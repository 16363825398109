import React, { useState } from "react";
import { Box, Button, useTheme, IconButton, Tooltip } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useGetNotificationsQuery, useDeleteNotificationMutation } from "state/api";
import Header from "components/Header";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import NotificationForm from "./NotificationForm";
import FlexBetween from "components/FlexBetween";

const Notification = () => {
  const theme = useTheme();
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [searchInput, setSearchInput] = useState("");
  const [openForm, setOpenForm] = useState(false);
  const [selectedNotification, setSelectedNotification] = useState(null);

  // Query notifications with pagination and search
  const { data, isLoading, refetch } = useGetNotificationsQuery({
    page: page + 1,
    limit: pageSize,
    search: searchInput
  });

  const [deleteNotification] = useDeleteNotificationMutation();

  const handleEdit = (notification) => {
    setSelectedNotification(notification);
    setOpenForm(true);
  };

  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this notification?")) {
      try {
        await deleteNotification(id);
        refetch();
      } catch (error) {
        console.error("Error deleting notification:", error);
      }
    }
  };

  const handleFormClose = () => {
    setOpenForm(false);
    setSelectedNotification(null);
    refetch();
  };

  const rows = data?.data?.map((item) => ({
    id: item._id,
    title: item.title,
    description: item.description,
    link: item.link,
    image: item.image,
    level: item.level || "All",
    categorySlug: item.categorySlug || "All",
    status: item.status,
    allowCountries: item.allowCountries?.join(", "),
    createdBy: item.createdBy?.name || "Unknown",
    createdAt: new Date(item.createdAt).toLocaleString(),
  })) || [];

  const columns = [
    { 
      field: "title", 
      headerName: "Title", 
      flex: 1,
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <span>{params.value}</span>
        </Tooltip>
      )
    },
    { 
      field: "description", 
      headerName: "Description", 
      flex: 1.5,
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <span>{params.value?.substring(0, 50)}...</span>
        </Tooltip>
      )
    },
    { field: "level", headerName: "Level", width: 100 },
    { field: "categorySlug", headerName: "Category", flex: 1 },
    { field: "status", headerName: "Status", width: 120 },
    { field: "allowCountries", headerName: "Countries", flex: 1 },
    { field: "createdBy", headerName: "Created By", flex: 1 },
    { field: "createdAt", headerName: "Created At", flex: 1 },
    {
      field: "actions",
      headerName: "Actions",
      width: 120,
      renderCell: (params) => (
        <Box>
          <IconButton onClick={() => handleEdit(params.row)}>
            <EditIcon />
          </IconButton>
          <IconButton onClick={() => handleDelete(params.row.id)} color="error">
            <DeleteIcon />
          </IconButton>
        </Box>
      ),
    },
  ];

  return (
    <Box m="1.5rem 2.5rem">
      <FlexBetween>
        <Header title="Notifications" subtitle="Manage your notifications" />
        <Button
          variant="contained"
          onClick={() => setOpenForm(true)}
          sx={{
            backgroundColor: theme.palette.secondary.light,
            color: theme.palette.background.alt,
            fontSize: "14px",
            fontWeight: "bold",
            padding: "10px 20px",
          }}
        >
          Add New Notification
        </Button>
      </FlexBetween>

      <Box
        mt="40px"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.primary.light,
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderTop: "none",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },
        }}
      >
        <DataGrid
          loading={isLoading}
          rows={rows}
          columns={columns}
          rowCount={data?.pagination?.total || 0}
          pagination
          page={page}
          pageSize={pageSize}
          paginationMode="server"
          onPageChange={(newPage) => setPage(newPage)}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          components={{ Toolbar: GridToolbar }}
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
        />
      </Box>

      {openForm && (
        <NotificationForm
          open={openForm}
          onClose={handleFormClose}
          notification={selectedNotification}
        />
      )}
    </Box>
  );
};

export default Notification;