import React, { useEffect, useState } from "react";
import {
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  useTheme,
  Tooltip
} from "@mui/material";
import {
  SettingsOutlined,
  ChevronLeft,
  ChevronRightOutlined,
  HomeOutlined,
  ShoppingCartOutlined,
  Groups2Outlined,
  ReceiptLongOutlined,
  PublicOutlined,
  PointOfSaleOutlined,
  TodayOutlined,
  CalendarMonthOutlined,
  AdminPanelSettingsOutlined,
  TrendingUpOutlined,
  PieChartOutlined,
  Engineering,
  AdsClick,
  ManageAccounts,
  QuestionAnswer,
  History,
  HistoryEdu,
  NotificationAddRounded,
  NotificationAddTwoTone,
  HistoryEduSharp,
  HistoryEduOutlined,
  CategoryOutlined,
  TimelineOutlined,
} from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";
import FlexBetween from "./FlexBetween";
import profileImage from "assets/profile.jpeg";
import { useSelector } from "react-redux";
import { useGetUserPermissionsQuery } from "state/api";

const navItems = [
  { text: "Dashboard", slug: 'dashboard', icon: <HomeOutlined />, permission: null },
  { text: "Categories", slug: 'categories', icon: <ShoppingCartOutlined />, permission: null },
  { text: "Users", slug: 'customers', icon: <Groups2Outlined />, permission: "manageUsers" },
  { text: "Management", slug: null, icon: null, permission: null },
  { text: "Teachers", slug: 'teachers', icon: <Engineering />, permission: null },
  { text: "Admins", slug: 'admin', icon: <AdminPanelSettingsOutlined />, permission: "manageUsers" },
  { text: "Profile", slug: 'profile', icon: <ManageAccounts />, permission: null },
  { text: "Question", slug: 'mcq-management', icon: <QuestionAnswer />, permission: "manageQuestions" },
  { text: "Ads", slug: 'ads', icon: <AdsClick />, permission: "manageAds" },
  { text: "Game History", slug: 'history', icon: <History />, permission: "viewGameHistory" },
  { text: "MCQ History", slug: 'mcqCompetition', icon: <HistoryEdu />, permission: "viewMcqHistory" },
  { text: "Notifications", slug: 'notifications', icon: <NotificationAddRounded />, permission: "manageNotifications" },
  {
    text: "Level Change History",
    icon: <HistoryEduOutlined />,
    slug: "level-change-history",
    permission: "viewLevelChangeHistory"
  },
  {
    text: "Coin Transactions",
    icon: <HistoryEduSharp />,
    slug: "coin-transactions",
    permission: "viewCoinHistory"
  },
  {
    text: "Shop Categories",
    icon: <CategoryOutlined />,
    slug: "shop-categories",
    permission: "manageShopCategories"
  },
  {
    text: "Activity Logs",
    icon: <TimelineOutlined/>,
    slug: "activity-logs",
    permission: "viewActivityLogs"
  },
];

const menuItems = {
  superadmin: ["dashboard", "categories", "teachers", "mcq-management", "profile", "customers", "admin","ads","history", "mcqCompetition", "notifications", "level-change-history", "coin-transactions", "shop-categories"],
  admin: ["dashboard", "categories", "teachers", "mcq-management", "profile", "customers", "admin","ads","history", "mcqCompetition", "notifications", "level-change-history", "coin-transactions", "shop-categories"],
  teacher: ["dashboard", "profile", "mcq-management"],
  user: ["dashboard", "profile"],
};

const Sidebar = ({
  drawerWidth,
  isSidebarOpen,
  setIsSidebarOpen,
  isNonMobile,
}) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();

  const user = useSelector((state) => state.auth.user);
  const [active, setActive] = useState("");
  const [userPermissions, setUserPermissions] = useState([]);

  // Fetch user permissions
  const { data: permissionsResponse } = useGetUserPermissionsQuery(
    user?._id,
    { skip: !user || user?.role === 'superadmin' }
  );

  useEffect(() => {
    if (permissionsResponse?.data?.permissions) {
      setUserPermissions(permissionsResponse.data.permissions);
    }
  }, [permissionsResponse]);

  useEffect(() => {
    setActive(pathname.substring(1));
  }, [pathname]);

  // Check if user has permission for a menu item
  const hasPermission = (permission) => {
    // If no permission required, or user is superadmin, always grant access
    if (!permission || user?.role === 'superadmin') return true;
    
    // Otherwise check if the user has the specific permission
    return userPermissions.includes(permission);
  };

  return (
    <Box component="nav">
      {isSidebarOpen && (
        <Drawer
          open={isSidebarOpen}
          onClose={() => setIsSidebarOpen(false)}
          variant="persistent"
          anchor="left"
          sx={{
            width: drawerWidth,
            "& .MuiDrawer-paper": {
              color: theme.palette.secondary[200],
              backgroundColor: theme.palette.background.alt,
              boxSizing: "border-box",
              borderWidth: isNonMobile ? 0 : "2px",
              width: drawerWidth,
            },
          }}
        >
          <Box width="100%">
            <Box m="1.5rem 2rem 2rem 3rem">
              <FlexBetween color={theme.palette.secondary.main}>
                <Box display="flex" alignItems="center" gap="0.5rem">
                  <Typography variant="h4" fontWeight="bold">
                    ORBIT
                  </Typography>
                </Box>
                {!isNonMobile && (
                  <IconButton onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
                    <ChevronLeft />
                  </IconButton>
                )}
              </FlexBetween>
            </Box>
            <List>
              {navItems.map(({ text, icon, slug, permission }) => {
                if (!icon) {
                  return (
                    <Typography key={text} sx={{ m: "2.25rem 0 1rem 3rem" }}>
                      {text}
                    </Typography>
                  );
                }
                
                const lcText = slug;
                const hasAccess = hasPermission(permission);

                return (
                  <Tooltip
                    key={text}
                    title={!hasAccess ? "You don't have permission to access this feature" : ""}
                    placement="right"
                  >
                    <ListItem disablePadding>
                      <ListItemButton
                        onClick={() => {
                          if (hasAccess) {
                            navigate(`/${lcText}`);
                            setActive(lcText);
                          }
                        }}
                        sx={{
                          backgroundColor:
                            active === lcText
                              ? theme.palette.secondary[300]
                              : "transparent",
                          color:
                            active === lcText
                              ? theme.palette.primary[600]
                              : hasAccess 
                                ? theme.palette.secondary[100]
                                : theme.palette.secondary[500], // Grayed out color for disabled items
                          opacity: hasAccess ? 1 : 0.6, // Make disabled items more transparent
                          pointerEvents: hasAccess ? 'auto' : 'none', // Remove click events for disabled items
                          "&:hover": {
                            backgroundColor: hasAccess 
                              ? theme.palette.secondary[300] 
                              : "transparent", // No hover effect for disabled items
                          },
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            ml: "2rem",
                            color:
                              active === lcText
                                ? theme.palette.primary[600]
                                : hasAccess 
                                  ? theme.palette.secondary[100]
                                  : theme.palette.secondary[500], // Grayed out icon for disabled items
                            opacity: hasAccess ? 1 : 0.6, // Make disabled icons more transparent
                          }}
                        >
                          {icon}
                        </ListItemIcon>
                        <ListItemText primary={text} />
                        {active === lcText && (
                          <ChevronRightOutlined sx={{ ml: "auto" }} />
                        )}
                      </ListItemButton>
                    </ListItem>
                  </Tooltip>
                );
              })}
            </List>
          </Box>

          {/* <Box position="absolute" bottom="2rem">
            <Divider />
            <FlexBetween textTransform="none" gap="1rem" m="1.5rem 2rem 0 3rem">
              <Box
                component="img"
                alt="profile"
                src={profileImage}
                height="40px"
                width="40px"
                borderRadius="50%"
                sx={{ objectFit: "cover" }}
              />
              <Box textAlign="left">
                <Typography
                  fontWeight="bold"
                  fontSize="0.9rem"
                  sx={{ color: theme.palette.secondary[100] }}
                >
                  {user.name}
                </Typography>
                <Typography
                  fontSize="0.8rem"
                  sx={{ color: theme.palette.secondary[200] }}
                >
                  {user.occupation}
                </Typography>
              </Box>
              <SettingsOutlined
                sx={{
                  color: theme.palette.secondary[300],
                  fontSize: "25px ",
                }}
              />
            </FlexBetween>
          </Box> */}
        </Drawer>
      )}
    </Box>
  );
};

export default Sidebar;
