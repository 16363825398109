import React, { useState } from "react";
import { Box, useTheme, Typography, Chip } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useGetTransactionsQuery, useGetCoinTransactionsQuery, useGetCustomersQuery } from "state/api";
import Header from "components/Header";
import DataGridCustomToolbar from "components/DataGridCustomToolbar";
import { formatDateTime } from "../../utils/formatDate";
import {
  Autocomplete,
  TextField,
  Button,
  Stack
} from "@mui/material";

const Transactions = () => {
  const theme = useTheme();

  // values to be sent to the backend
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [sort, setSort] = useState({});
  const [search, setSearch] = useState("");

  const [searchInput, setSearchInput] = useState("");
  const { data, isLoading } = useGetTransactionsQuery({
    page,
    pageSize,
    sort: JSON.stringify(sort),
    search,
  });

  const columns = [
    {
      field: "_id",
      headerName: "ID",
      flex: 1,
    },
    {
      field: "userId",
      headerName: "User ID",
      flex: 1,
    },
    {
      field: "createdAt",
      headerName: "CreatedAt",
      flex: 1,
    },
    {
      field: "products",
      headerName: "# of Products",
      flex: 0.5,
      sortable: false,
      renderCell: (params) => params.value.length,
    },
    {
      field: "cost",
      headerName: "Cost",
      flex: 1,
      renderCell: (params) => `$${Number(params.value).toFixed(2)}`,
    },
  ];

  return (
    <Box m="1.5rem 2.5rem">
      <Header title="TRANSACTIONS" subtitle="Entire list of transactions" />
      <Box
        height="80vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.primary.light,
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderTop: "none",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },
        }}
      >
        <DataGrid
          loading={isLoading || !data}
          getRowId={(row) => row._id}
          rows={(data && data.transactions) || []}
          columns={columns}
          rowCount={(data && data.total) || 0}
          rowsPerPageOptions={[20, 50, 100]}
          pagination
          page={page}
          pageSize={pageSize}
          paginationMode="server"
          sortingMode="server"
          onPageChange={(newPage) => setPage(newPage)}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          onSortModelChange={(newSortModel) => setSort(...newSortModel)}
          components={{ Toolbar: DataGridCustomToolbar }}
          componentsProps={{
            toolbar: { searchInput, setSearchInput, setSearch },
          }}
        />
      </Box>
    </Box>
  );
};

const CoinTransactions = () => {
  const theme = useTheme();
  const [selectedUser, setSelectedUser] = useState(null);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);

  const { data: customers } = useGetCustomersQuery();
  const { data, isLoading } = useGetCoinTransactionsQuery({
    page,
    limit: pageSize,
    userId: selectedUser?._id
  });

  const columns = [
    {
      field: "userId",
      headerName: "User",
      flex: 1,
      valueGetter: (params) => 
        `${params.row.userId?.name} (${params.row.userId?.email})` || "N/A",
    },
    {
      field: "trType",
      headerName: "Type",
      flex: 0.5,
    },
    {
      field: "desc",
      headerName: "Description",
      flex: 1,
    },
    {
      field: "amount",
      headerName: "Amount",
      flex: 0.5,
      type: 'number',
    },
    {
      field: "balance",
      headerName: "Balance",
      flex: 0.5,
      type: 'number',
    },
    {
      field: "createdAt",
      headerName: "Date",
      flex: 0.7,
      valueGetter: (params) => formatDateTime(params.row.createdAt),
    },
  ];

  const handleUserChange = (event, newValue) => {
    setSelectedUser(newValue);
    setPage(1); // Reset to first page when filter changes
  };

  const handleClearFilter = () => {
    setSelectedUser(null);
    setPage(1);
  };

  return (
    <Box m="1.5rem 2.5rem">
      <Header title="Coin Transactions" subtitle="Track all coin balance changes" />
      
      {/* Filter Section */}
      <Stack 
        direction="row" 
        spacing={2} 
        sx={{ mb: 3, mt: 2 }}
        alignItems="center"
      >
        <Autocomplete
          sx={{ width: 300 }}
          options={customers || []}
          getOptionLabel={(option) => `${option.name} (${option.email})`}
          value={selectedUser}
          onChange={handleUserChange}
          renderInput={(params) => (
            <TextField 
              {...params} 
              label="Filter by Customer"
              variant="outlined"
              size="small"
            />
          )}
        />
        {selectedUser && (
          <Button 
            variant="outlined" 
            color="secondary"
            onClick={handleClearFilter}
            size="small"
          >
            Clear Filter
          </Button>
        )}
      </Stack>

      <Box
        mt="20px"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.primary.light,
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderTop: "none",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },
        }}
      >
        <DataGrid
          loading={isLoading || !data}
          getRowId={(row) => row._id}
          rows={data?.data || []}
          columns={columns}
          components={{
            Toolbar: GridToolbar,
          }}
          paginationModel={{ page: page - 1, pageSize }}
          onPaginationModelChange={(model) => {
            setPage(model.page + 1);
            setPageSize(model.pageSize);
          }}
          pageSizeOptions={[10, 25, 50, 100]}
          rowCount={data?.pagination?.total || 0}
          paginationMode="server"
        />
      </Box>
    </Box>
  );
};

export default CoinTransactions;
