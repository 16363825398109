import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  Grid,
  Typography,
  IconButton,
  Chip,
  OutlinedInput,
  InputAdornment,
  ListSubheader,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useCreateNotificationMutation, useUpdateNotificationMutation, useGetCategoryQuery } from 'state/api';
import { countries } from 'utils/countries';
import { GridSearchIcon } from '@mui/x-data-grid';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const NotificationForm = ({ open, onClose, notification }) => {
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    link: '',
    level: 'All',
    categorySlug: 'All',
    allowCountries: [],
    status: 'Active',
    image: null
  });
  const [imagePreview, setImagePreview] = useState(null);
  const [searchText, setSearchText] = useState('');

  const [createNotification] = useCreateNotificationMutation();
  const [updateNotification] = useUpdateNotificationMutation();
  const { data: categories } = useGetCategoryQuery();

  useEffect(() => {
    if (notification) {
      setFormData({
        title: notification.title || '',
        description: notification.description || '',
        link: notification.link || '',
        level: notification.level || 'All',
        categorySlug: notification.categorySlug || 'All',
        allowCountries: notification?.allowCountries ? (Array.isArray(notification.allowCountries) ? notification.allowCountries : notification.allowCountries.split(',').map(c => c.trim())) : [],
        status: notification.status || 'Active',
        image: null || notification.image,
      });
      setImagePreview(notification.image ? `${process.env.REACT_APP_API_URL}/img/${notification.image}` : null);
    }
  }, [notification]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    
    if (name === 'allowCountries') {
      // Handle the multiple select values properly
      let processedValue = value;
      
      // If 'all' is selected, only keep 'all'
      if (Array.isArray(value) && value.includes('all')) {
        processedValue = ['all'];
      }
      
      setFormData(prev => ({
        ...prev,
        [name]: processedValue
      }));
    } else {
      setFormData(prev => ({
        ...prev,
        [name]: value
      }));
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFormData(prev => ({
        ...prev,
        image: file
      }));
      setImagePreview(URL.createObjectURL(file));
    }
  };

  

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      const formDataToSend = new FormData();
      
      // Append all form fields
      Object.keys(formData).forEach(key => {
        if (key === 'allowCountries' && Array.isArray(formData[key])) {
          // Send allowCountries as a proper array
          formDataToSend.append('allowCountries', JSON.stringify(formData[key]));
        } else {
          formDataToSend.append(key, formData[key]);
        }
      });

      // if (formData.image) {
      //   formDataToSend.append('image', formData.image);
      // }

      if (notification?.id) {
        await updateNotification({
          id: notification.id,
          formData: formDataToSend
        }).unwrap();
      } else {
        await createNotification(formDataToSend).unwrap();
      }
      onClose();
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const filteredCountries = countries.filter(country => 
    country.name.toLowerCase().includes(searchText.toLowerCase())
  );

  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6">
            {notification ? 'Edit Notification' : 'Create New Notification'}
          </Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                name="title"
                label="Title"
                fullWidth
                required
                value={formData.title}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="description"
                label="Description"
                fullWidth
                multiline
                rows={4}
                value={formData.description}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="link"
                label="Link"
                fullWidth
                value={formData.link}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel>Level</InputLabel>
                <Select
                  name="level"
                  value={formData.level}
                  onChange={handleChange}
                  label="Level"
                >
                  <MenuItem value={"All"}>All Levels</MenuItem>
                  {[...Array(25)].map((_, i) => (
                    <MenuItem key={i + 1} value={i + 1}>
                      Level {i + 1}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel>Category</InputLabel>
                <Select
                  name="categorySlug"
                  value={formData.categorySlug}
                  onChange={handleChange}
                  label="Category"
                >
                  <MenuItem value={"All"}>All</MenuItem>
                  {categories?.map((category) => (
                    <MenuItem key={category._id} value={category.slug}>
                      {category.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel>Status</InputLabel>
                <Select
                  name="status"
                  value={formData.status}
                  onChange={handleChange}
                  label="Status"
                >
                  <MenuItem value="Active">Save Only</MenuItem>
                  <MenuItem value="Sent">Sent</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel>Allow Countries</InputLabel>
                <Select
                  multiple
                  name="allowCountries"
                  value={Array.isArray(formData.allowCountries) ? formData.allowCountries : []}
                  onChange={handleChange}
                  input={<OutlinedInput label="Allow Countries" />}
                  renderValue={(selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      {(Array.isArray(selected) ? selected : []).map((value) => (
                        <Chip 
                          key={value} 
                          label={
                            value === 'all' 
                              ? 'All Countries' 
                              : countries.find(c => c.code === value)?.name || value
                          } 
                        />
                      ))}
                    </Box>
                  )}
                  MenuProps={MenuProps}
                >
                  <MenuItem value="all">
                    <em>All Countries</em>
                  </MenuItem>
                  <ListSubheader>
                    <TextField
                      size="small"
                      autoFocus
                      placeholder="Type to search..."
                      fullWidth
                      value={searchText}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <GridSearchIcon />
                          </InputAdornment>
                        )
                      }}
                      onChange={(e) => setSearchText(e.target.value)}
                      onKeyDown={(e) => {
                        if (e.key !== 'Escape') {
                          e.stopPropagation();
                        }
                      }}
                    />
                  </ListSubheader>
                  {filteredCountries.map((country) => (
                    <MenuItem
                      key={country.code}
                      value={country.code}
                      disabled={formData.allowCountries.includes('all')}
                      selected={formData.allowCountries.includes(country.code)}
                    >
                      {country.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <input
                accept="image/*"
                type="file"
                id="image-upload"
                style={{ display: 'none' }}
                onChange={handleImageChange}
              />
              
              <label htmlFor="image-upload">
                <Button
                  variant="outlined"
                  component="span"
                  fullWidth
                  sx={{
                    backgroundColor: 'primary.light',
                    color: 'white',
                    border: '2px solid',
                    borderColor: 'primary.main',
                    '&:hover': {
                      backgroundColor: 'primary.main',
                      color: 'white',
                    },
                  }}
                >
                  {formData.image ? 'Change Image' : 'Upload Image'}
                </Button>
              </label>
              <p style={{ fontSize: '12px', color: 'gray' }}>Recommended dimensions are 720 x 360 px, with a minimum of 300 x 300 px </p>
              {imagePreview && (
                <Box mt={2}>
                  <img src={imagePreview} alt="Image Preview" style={{ maxWidth: '100%', maxHeight: '200px' }} />
                </Box>
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={onClose}
            sx={{
              backgroundColor: 'error.light',
              color: 'white',
              '&:hover': {
                backgroundColor: 'error.main',
              },
            }}
          >
            Cancel
          </Button>
          <Button type="submit" variant="contained" color="primary">
            {notification ? 'Update' : 'Create'}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default NotificationForm;