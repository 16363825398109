import React, { useState } from "react";
import { Box, useTheme, Stack, Typography } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useGetHistoriesQuery } from "../../state/api";
import Header from "components/Header";

const History = () => {
  const theme = useTheme();
  const { data: histories, isLoading } = useGetHistoriesQuery();
  const [filteredRowCount, setFilteredRowCount] = useState(0);
  const [searchText, setSearchText] = useState("");

  const rows = histories?.map((item) => ({
    id: item._id,
    gameId: item.gameId,
    winnerUser: item.winnerUserId?.name || "Unknown",
    looserUser: item.looserUserId?.name || "Unknown", 
    categorySlug: item.categoryText,
    level: item.level,
    winnerLevel: item.winnerLevel,
    looserLevel: item.looserLevel,
    dateTime: new Date(item.dateTime).toLocaleString(),
  })) || [];

  const columns = [
    { field: "gameId", headerName: "Game ID", flex: 1, filterable: true },
    { field: "winnerUser", headerName: "Winner", flex: 1, filterable: true },
    { field: "looserUser", headerName: "Looser", flex: 1, filterable: true },
    { field: "categorySlug", headerName: "Category", flex: 1, filterable: true },
    { field: "level", headerName: "Level", flex: 1, filterable: true },
    { field: "winnerLevel", headerName: "Winner Level", flex: 1, filterable: true },
    { field: "looserLevel", headerName: "Looser Level", flex: 1, filterable: true },
    { field: "dateTime", headerName: "Date Time", flex: 1, filterable: true },
  ];

  // Global search filter
  const filteredData = rows.filter(row => {
    if (!searchText) return true;
    const searchLower = searchText.toLowerCase();
    return (
      row.gameId?.toString().toLowerCase().includes(searchLower) ||
      row.winnerUser?.toLowerCase().includes(searchLower) ||
      row.looserUser?.toLowerCase().includes(searchLower) ||
      row.categorySlug?.toLowerCase().includes(searchLower)
    );
  });

  return (
    <Box sx={{ padding: { xs: 1, md: 3 } }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" flexWrap="wrap" gap={2}>
        <Header title="Game History" subtitle="View the history of games played" />
        
        {/* Search and Stats Section */}
        <Stack direction="row" spacing={2} alignItems="center">
          <Box>
            <Typography variant="subtitle2" color="text.secondary">
              Total Games: {rows.length}
            </Typography>
            <Typography variant="subtitle2" color="text.secondary">
              Filtered: {filteredRowCount || filteredData.length}
            </Typography>
          </Box>
        </Stack>
      </Box>

      <Box
        mt="20px"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.primary.light,
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderTop: "none",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },
        }}
      >
        <DataGrid
          loading={isLoading}
          getRowId={(row) => row.id}
          rows={filteredData}
          columns={columns}
          components={{
            Toolbar: GridToolbar,
          }}
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
          onFilterModelChange={(filterModel) => {
            const filteredRows = filterModel.items.length > 0 ? 
              filteredData.filter(row => {
                return filterModel.items.every(filter => {
                  const value = row[filter.columnField];
                  const filterValue = filter.value;
                  if (!value || !filterValue) return false;
                  return value.toString().toLowerCase().includes(filterValue.toLowerCase());
                });
              }) : 
              filteredData;
            setFilteredRowCount(filteredRows.length);
          }}
          initialState={{
            pagination: { paginationModel: { pageSize: 25 } },
          }}
          pageSizeOptions={[10, 25, 50, 100]}
        />
      </Box>
    </Box>
  );
};

export default History;