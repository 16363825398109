import React from "react";
import { Box, Typography, Card, CardMedia, Button } from "@mui/material";
import { useMediaQuery } from "@mui/material";

const ReleaseMessage = () => {
  const isMobile = useMediaQuery("(max-width:600px)");

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        p: 4,
        background: "linear-gradient(to right,rgb(19, 113, 130), #000)",
        color: "white",
        borderRadius: 3,
        flexDirection: "column",
      }}
    >
      <Card
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          p: 4,
          background: "transparent",
          color: "white",
          borderRadius: 3,
          flexDirection: isMobile ? "column" : "row",
          maxHeight: "100%",
        }}
      >
        {/* Left Section - Text */}
        <Box sx={{ flex: 1, width: isMobile ? "100%" : "60%" }}>
          <h1 className="text-3xl font-bold text-gray-800">
            Orbit Competition – The Ultimate MCQ Challenge! 🚀
          </h1>
          <p className="mt-4 text-lg text-gray-600">
            Are you ready to put your knowledge to the test? <b>Orbit Competition</b> brings you an exciting <b>MCQ-based challenge</b>, where you can learn, compete, and level up based on your category performance!
          </p>
          <p className="mt-4 text-xl font-semibold text-blue-600">
            Competition Starts: January 31
          </p>
          <p className="mt-4 text-lg text-gray-600">
            🏆 <b>Challenge Your Mind | Learn & Compete | Level Up | Win Gift</b>
          </p>

          <h2 className="mt-6 text-2xl font-semibold text-gray-800">
            Why Join Orbit Competition?
          </h2>
          <ul className="mt-3 text-gray-600 text-lg space-y-2">
            <li>✅ <b>Engaging MCQs</b> – Covering multiple categories & difficulty levels</li>
            <li>✅ <b>Learn Before You Compete</b> – Access learning materials before starting</li>
            <li>✅ <b>Level Up</b> – No rewards, but progress through levels in your chosen category</li>
            <li>✅ <b>Fair & Transparent</b> – Every second counts</li>
          </ul>

          <h2 className="mt-6 text-2xl font-semibold text-gray-800">
            How It Works
          </h2>
          <ul className="mt-3 text-gray-600 text-lg space-y-2">
            <li>1️⃣ <b>Register</b> – Sign up and create your profile</li>
            <li>2️⃣ <b>Learn</b> – Access materials to prepare for the competition</li>
            <li>3️⃣ <b>Choose a Category</b> – Pick your preferred topic</li>
            <li>4️⃣ <b>Start Competing</b> – Answer questions within the given time</li>
            <li>5️⃣ <b>Level Up</b> – Improve your rank in your chosen category</li>
            <li>6️⃣ <b>Win Prizes</b> – Win exciting prizes based on your performance</li>
          </ul>

          <p className="mt-6 text-xl font-semibold text-gray-800">
            🔹 Think Fast. Learn Smart. Level Up. Win Gift! 🔹
          </p>

          <p className="mt-6 text-lg text-gray-600">
            <a
              className="text-white hover:text-600 hover:underline"
              href="https://orbitcompetition.com/privacy-policy"
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policy
            </a>
          </p>

          <Button
            variant="contained"
            color="secondary"
            href="https://play.google.com/store/apps/details?id=com.orbitcompitition.gamequiz"
            target="_blank"
            rel="noopener noreferrer"
            className="mt-6 px-6 py-3 mb-4 text-lg font-semibold"
          >
            Download App and Register Now
          </Button>
        </Box>

        {/* Right Section - Image */}
        <Box sx={{ width: isMobile ? "100%" : "40%" }}>
          <img
            src="/images/home-phone.png"
            alt="Home Screen" height={isMobile ? "100%" : "60%"} width={isMobile ? "100%" : "60%"}
          />
        </Box>
      </Card>
    </Box>
  );
};

export default ReleaseMessage;

