import React, { useState } from "react";
import {
  Box,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { useGetLevelUsersQuery } from "state/api";
import Header from "components/Header";

import { useNavigate, useLocation } from 'react-router-dom';
import { DataGrid } from "@mui/x-data-grid";

const CategoryLevelUsers = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const { com_type_name, slug, level } = location.state;
  const isNonMobile = useMediaQuery("(min-width: 1000px)");
  
  // Pagination state
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 100000,
  });

  // Use the query hook with pagination
  const { data: usersData, isLoading } = useGetLevelUsersQuery({
    categorySlug: slug,
    level: level,
    page: paginationModel.page,
    pageSize: paginationModel.pageSize,
  });

  const goLevelUserHistory = (userId, userName) => {
    navigate('/category/level/user/history', {
      state: {
        userId,
        userName,
        catetoryName: com_type_name,
        categorySlug: slug,
        level: level
      }
    });
  };

  const columns = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
    },
    {
      field: "phone",
      headerName: "Phone",
      flex: 1,
    },
    {
      field: "level",
      headerName: "Level",
      flex: 0.5,
      renderCell: () => level,
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      renderCell: (params) => (
        <Typography
          color={theme.palette.secondary[400]}
          sx={{
            cursor: "pointer",
            "&:hover": { color: theme.palette.secondary[100] },
          }}
          onClick={() => goLevelUserHistory(params.row._id, params.row.name)}
        >
          View History
        </Typography>
      ),
    },
  ];

  return (
    <Box m="1.5rem 2.5rem">
      <Header 
        title={`Users of ${com_type_name}`} 
        subtitle={`listing all the users from level ${level} and ${com_type_name}`} 
      />
      <Box
        mt="40px"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.primary.light,
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderTop: "none",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },
        }}
      >
        <DataGrid
          loading={isLoading}
          getRowId={(row) => row._id}
          rows={usersData?.data?.usersList || []}
          columns={columns}
          rowCount={usersData?.data?.pagination?.total || 0}
          pagination
          paginationModel={paginationModel}
          pageSizeOptions={[10, 20, 50]}
          paginationMode="server"
          onPaginationModelChange={setPaginationModel}
          autoHeight
          disableSelectionOnClick
        />
      </Box>
    </Box>
  );
};

export default CategoryLevelUsers;