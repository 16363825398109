import React, { useState } from "react";
import {
  Box,
  Button,
  useTheme,
  Dialog,
  Stack,
  IconButton,
  Typography,
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { toast } from "react-toastify";
import { useGetAdsQuery, useDeleteAdMutation } from "state/api";
import Header from "components/Header";
import AdForm from "./AdForm";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import VisibilityIcon from '@mui/icons-material/Visibility';

const Ads = () => {
  const theme = useTheme();
  const { data: adsResponse, isLoading } = useGetAdsQuery();
  const [deleteAd] = useDeleteAdMutation();

  const [previewOpen, setPreviewOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [selectedAd, setSelectedAd] = useState(null);

  const rows = adsResponse?.data?.map((item) => ({
    ...item,
    id: item._id,
  })) || [];

  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this ad?")) {
      try {
        await deleteAd(id).unwrap();
        toast.success("Ad deleted successfully!");
      } catch (error) {
        toast.error("Failed to delete the ad.");
      }
    }
  };

  const handlePreview = (file) => {
    setSelectedFile(file);
    setPreviewOpen(true);
  };

  const handleOpenForm = (ad = null) => {
    setSelectedAd(ad);
    setIsFormOpen(true);
  };

  const handleCloseForm = () => {
    setSelectedAd(null);
    setIsFormOpen(false);
  };

  const columns = [
    { 
      field: "adTitle", 
      headerName: "Title", 
      flex: 1,
      renderCell: (params) => (
        <Box>
          <Typography variant="body1">{params.row.adTitle}</Typography>
          <Typography variant="caption" color="textSecondary">
            {params.row.adDescription}
          </Typography>
        </Box>
      )
    },
    { field: "adType", headerName: "Ad Type", flex: .4 },
    { field: "adFileType", headerName: "File Type", flex: 1, hide: false },
    {
      field: "preview",
      headerName: "Preview",
      flex: .6,
      renderCell: (params) => (
        <Box
          sx={{
            height: '50px',
            width: '50px',
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
          onClick={() => handlePreview(params.row)}
        >
          {params.row.adFileType === "Video" ? (
            <video 
              src={`${process.env.REACT_APP_API_URL}/img/${params.row.adFile}`}
              style={{ maxHeight: '100%', maxWidth: '100%' }}
            />
          ) : (
            <img
              src={`${process.env.REACT_APP_API_URL}/img/${params.row.adFile}`}
              alt="Preview"
              style={{ maxHeight: '100%', maxWidth: '100%', objectFit: 'contain' }}
            />
          )}
        </Box>
      ),
    },
    { field: "viewLimit", headerName: "View Limit", flex: .5 },
    { field: "duration", headerName: "Duration", flex: .4, hide: true },
    { field: "viewed", headerName: "Viewed", flex: .4 },
    { 
      field: "creator", 
      headerName: "Creator", 
      flex: .8,
      hide: false,
      renderCell: (params) => (
        <Typography>
          {params.row.creator?.name || params.row.creator || 'N/A'}
        </Typography>
      )
    },
    { field: "cost", headerName: "Cost", flex: .6, hide: true },
    { 
      field: "status", 
      headerName: "Status", 
      flex: 0.6,
      renderCell: (params) => {
        const status = params.value;
        let color;
        switch (status) {
          case 'Active':
            color = 'success.main';
            break;
          case 'Inactive':
            color = 'warning.main';
            break;
          case 'Done':
            color = 'error.main';
            break;
          default:
            color = 'info.main';
        }
        
        return (
          <Box
            sx={{
              backgroundColor: color,
              padding: '4px 8px',
              borderRadius: '4px',
              color: 'white',
              fontSize: '0.75rem',
              fontWeight: 'bold'
            }}
          >
            {status}
          </Box>
        );
      }
    },
    { 
      field: "allowCountries", 
      headerName: "Allowed Countries", 
      flex: 1,
      renderCell: (params) => {
        const countries = params.value || [];
        return (
          <Typography>
            {countries.length === 0 ? 'All Countries' : countries.join(', ')}
          </Typography>
        );
      }
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      renderCell: (params) => (
        <Stack direction="row" spacing={1}>
          <IconButton
            size="small"
            onClick={() => handlePreview(params.row)}
            sx={{ 
              color: 'white',
              backgroundColor: 'info.main',
              '&:hover': { backgroundColor: 'info.dark' }
            }}
          >
            <VisibilityIcon />
          </IconButton>
          <IconButton
            size="small"
            onClick={() => handleOpenForm(params.row)}
            sx={{ 
              color: 'white',
              backgroundColor: 'primary.main',
              '&:hover': { backgroundColor: 'primary.dark' }
            }}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            size="small"
            onClick={() => handleDelete(params.row.id)}
            sx={{ 
              color: 'white',
              backgroundColor: 'error.main',
              '&:hover': { backgroundColor: 'error.dark' }
            }}
          >
            <DeleteIcon />
          </IconButton>
        </Stack>
      ),
    },
  ];

  return (
    <Box sx={{ padding: { xs: 1, md: 3 } }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Header title="ADS" subtitle="Managing ads" />
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => handleOpenForm()}
        >
          Create Ad
        </Button>
      </Box>

      <Box
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.primary.light,
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderTop: "none",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },
        }}
      >
        <DataGrid
          loading={isLoading}
          getRowId={(row) => row.id}
          rows={rows}
          columns={columns}
          components={{
            Toolbar: GridToolbar,
          }}
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
          initialState={{
            pagination: { paginationModel: { pageSize: 25 } },
          }}
          pageSizeOptions={[10, 25, 50, 100]}
        />
      </Box>

      <Dialog open={previewOpen} onClose={() => setPreviewOpen(false)}>
        <Box p={2}>
          {selectedFile?.adFileType === "Video" ? (
            <video width="100%" controls>
              <source src={`${process.env.REACT_APP_API_URL}/img/${selectedFile?.adFile}`} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          ) : (
            <img
              src={`${process.env.REACT_APP_API_URL}/img/${selectedFile?.adFile}`}
              alt="Preview"
              style={{ maxWidth: "100%", maxHeight: "400px" }}
            />
          )}
        </Box>
      </Dialog>

      <AdForm 
        open={isFormOpen}
        onClose={handleCloseForm}
        ad={selectedAd}
      />
    </Box>
  );
};

export default Ads;