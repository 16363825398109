import React, { useState, useEffect } from "react";
import {
  Box,
  useTheme,
  Typography,
  Card,
  CardContent,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  IconButton,
  Chip,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  Stack,
  Pagination,
} from "@mui/material";
import {
  FilterList as FilterListIcon,
  Close as CloseIcon,
  Search as SearchIcon,
  Refresh as RefreshIcon,
  AccountCircle,
  CalendarToday,
  Category
} from "@mui/icons-material";
import { DataGrid } from "@mui/x-data-grid";
import { useGetActivitiesQuery, useGetActivityModulesQuery, useGetAdminsQuery } from "state/api";
import Header from "components/Header";
import { formatRelative } from 'date-fns';

const ActivityLogs = () => {
  const theme = useTheme();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [filters, setFilters] = useState({
    userId: '',
    action: '',
    module: '',
    startDate: '',
    endDate: '',
    search: ''
  });
  const [showFilters, setShowFilters] = useState(false);
  const [selectedLog, setSelectedLog] = useState(null);

  // API queries
  const { data, isLoading, refetch } = useGetActivitiesQuery({ 
    page, 
    limit: pageSize,
    ...filters
  });
  const { data: modulesData } = useGetActivityModulesQuery();
  const { data: usersData } = useGetAdminsQuery();

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters(prev => ({ ...prev, [name]: value }));
  };

  const handleApplyFilters = () => {
    setPage(1); // Reset to first page when applying filters
    setShowFilters(false);
  };

  const handleClearFilters = () => {
    setFilters({
      userId: '',
      action: '',
      module: '',
      startDate: '',
      endDate: '',
      search: ''
    });
    setPage(1);
  };

  const handleViewDetails = (log) => {
    setSelectedLog(log);
  };

  const handleCloseDetails = () => {
    setSelectedLog(null);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return formatRelative(date, new Date());
  };

  // Action chip colors
  const getActionColor = (action) => {
    switch (action) {
      case 'CREATE':
        return 'success';
      case 'UPDATE':
        return 'primary';
      case 'DELETE':
        return 'error';
      case 'STATUS_CHANGE':
        return 'warning';
      case 'LOGIN':
        return 'info';
      case 'LOGOUT':
        return 'default';
      default:
        return 'default';
    }
  };

  const columns = [
    {
      field: "createdAt",
      headerName: "Time",
      flex: 0.7,
      renderCell: (params) => formatDate(params.value)
    },
    {
      field: "userId",
      headerName: "User",
      flex: 1,
      renderCell: (params) => (
        <Box>
          <Typography variant="body2">
            {params.value?.name || 'Unknown'}
          </Typography>
          <Typography variant="caption" color="textSecondary">
            {params.value?.email || ''}
          </Typography>
        </Box>
      ),
    },
    {
      field: "action",
      headerName: "Action",
      flex: 0.5,
      renderCell: (params) => (
        <Chip 
          label={params.value} 
          size="small" 
          color={getActionColor(params.value)} 
        />
      ),
    },
    {
      field: "module",
      headerName: "Module",
      flex: 0.7,
    },
    {
      field: "description",
      headerName: "Description",
      flex: 1.5,
      renderCell: (params) => (
        <Typography
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitLineClamp: 2,
            WebkitBoxOrient: 'vertical',
          }}
        >
          {params.value}
        </Typography>
      )
    },
    {
      field: "view",
      headerName: "Details",
      flex: 0.5,
      renderCell: (params) => (
        <Button
          size="small"
          onClick={() => handleViewDetails(params.row)}
          variant="contained"
          color="primary"
          sx={{
            borderRadius: '4px',
            textTransform: 'none',
            boxShadow: 'none'
          }}
        >
          View
        </Button>
      ),
    },
  ];

  return (
    <Box m="1.5rem 2.5rem">
      <Header title="ACTIVITY LOGS" subtitle="Tracking user activities across the system" />
      <Box mb={2} display="flex" justifyContent="space-between" alignItems="center">
        <Box display="flex" alignItems="center">
          <Button
            startIcon={<FilterListIcon />}
            onClick={() => setShowFilters(!showFilters)}
            variant={showFilters ? "contained" : "outlined"}
            sx={{ mr: 1 }}
          >
            Filters
          </Button>
          <Button
            startIcon={<RefreshIcon />}
            onClick={() => refetch()}
            variant="outlined"
          >
            Refresh
          </Button>
        </Box>
        
        <TextField
          variant="outlined"
          size="small"
          placeholder="Search logs..."
          value={filters.search}
          onChange={(e) => setFilters(prev => ({ ...prev, search: e.target.value }))}
          InputProps={{
            startAdornment: <SearchIcon sx={{ mr: 1, color: 'text.secondary' }} />,
          }}
          sx={{ width: 300 }}
        />
      </Box>

      {/* Filters panel */}
      {showFilters && (
        <Card sx={{ mb: 3 }}>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12} md={3}>
                <FormControl fullWidth size="small">
                  <InputLabel>User</InputLabel>
                  <Select
                    name="userId"
                    value={filters.userId}
                    onChange={handleFilterChange}
                    label="User"
                  >
                    <MenuItem value="">All Users</MenuItem>
                    {usersData?.map((user) => (
                      <MenuItem key={user._id} value={user._id}>
                        {user.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={3}>
                <FormControl fullWidth size="small">
                  <InputLabel>Action</InputLabel>
                  <Select
                    name="action"
                    value={filters.action}
                    onChange={handleFilterChange}
                    label="Action"
                  >
                    <MenuItem value="">All Actions</MenuItem>
                    <MenuItem value="CREATE">Create</MenuItem>
                    <MenuItem value="UPDATE">Update</MenuItem>
                    <MenuItem value="DELETE">Delete</MenuItem>
                    <MenuItem value="STATUS_CHANGE">Status Change</MenuItem>
                    <MenuItem value="LOGIN">Login</MenuItem>
                    <MenuItem value="LOGOUT">Logout</MenuItem>
                    <MenuItem value="OTHER">Other</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={3}>
                <FormControl fullWidth size="small">
                  <InputLabel>Module</InputLabel>
                  <Select
                    name="module"
                    value={filters.module}
                    onChange={handleFilterChange}
                    label="Module"
                  >
                    <MenuItem value="">All Modules</MenuItem>
                    {modulesData?.data?.map((module) => (
                      <MenuItem key={module} value={module}>
                        {module}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  name="startDate"
                  label="Start Date"
                  type="date"
                  fullWidth
                  size="small"
                  value={filters.startDate}
                  onChange={handleFilterChange}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  name="endDate"
                  label="End Date"
                  type="date"
                  fullWidth
                  size="small"
                  value={filters.endDate}
                  onChange={handleFilterChange}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={12} md={9} display="flex" justifyContent="flex-end">
                <Button
                  variant="outlined"
                  onClick={handleClearFilters}
                  sx={{ mr: 1 }}
                >
                  Clear
                </Button>
                <Button
                  variant="contained"
                  onClick={handleApplyFilters}
                >
                  Apply Filters
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      )}

      <Box
        height="70vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.primary.light,
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderTop: "none",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },
        }}
      >
        <DataGrid
          loading={isLoading}
          getRowId={(row) => row._id}
          rows={data?.data || []}
          columns={columns}
          pagination
          pageSize={pageSize}
          rowsPerPageOptions={[10, 20, 50]}
          rowCount={data?.pagination?.total || 0}
          page={page - 1}
          onPageChange={(newPage) => setPage(newPage + 1)}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          paginationMode="server"
          disableSelectionOnClick
        />
      </Box>

      {/* Activity Details Dialog */}
      <Dialog
        open={!!selectedLog}
        onClose={handleCloseDetails}
        maxWidth="md"
        fullWidth
      >
        {selectedLog && (
          <>
            <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography variant="h6">Activity Details</Typography>
              <IconButton onClick={handleCloseDetails} size="small">
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Stack direction="row" spacing={1} mb={1}>
                    <Chip 
                      icon={<CalendarToday />} 
                      label={new Date(selectedLog.createdAt).toLocaleString()} 
                    />
                    <Chip 
                      icon={<AccountCircle />} 
                      label={selectedLog.userId?.name || 'Unknown'} 
                    />
                    <Chip 
                      icon={<Category />} 
                      label={selectedLog.module} 
                    />
                    <Chip 
                      label={selectedLog.action} 
                      color={getActionColor(selectedLog.action)} 
                    />
                  </Stack>
                </Grid>
                
                <Grid item xs={12}>
                  <Typography variant="subtitle1" gutterBottom>Description</Typography>
                  <Typography variant="body1">{selectedLog.description}</Typography>
                </Grid>
                
                <Grid item xs={12}>
                  <Divider sx={{ my: 2 }} />
                  <Typography variant="subtitle1" gutterBottom>Additional Details</Typography>
                  {selectedLog.details ? (
                    <Box
                      sx={{
                        p: 2,
                        backgroundColor: theme.palette.background.alt,
                        borderRadius: 1,
                        maxHeight: '300px',
                        overflow: 'auto'
                      }}
                    >
                      <pre style={{ margin: 0, overflow: 'auto' }}>
                        {JSON.stringify(selectedLog.details, null, 2)}
                      </pre>
                    </Box>
                  ) : (
                    <Typography variant="body2" color="text.secondary">No additional details</Typography>
                  )}
                </Grid>
                
                {(selectedLog.ipAddress || selectedLog.userAgent) && (
                  <Grid item xs={12}>
                    <Divider sx={{ my: 2 }} />
                    <Typography variant="subtitle1" gutterBottom>Connection Information</Typography>
                    {selectedLog.ipAddress && (
                      <Typography variant="body2" gutterBottom>
                        <strong>IP Address:</strong> {selectedLog.ipAddress}
                      </Typography>
                    )}
                    {selectedLog.userAgent && (
                      <Typography variant="body2">
                        <strong>User Agent:</strong> {selectedLog.userAgent}
                      </Typography>
                    )}
                  </Grid>
                )}
              </Grid>
            </DialogContent>
          </>
        )}
      </Dialog>
    </Box>
  );
};

export default ActivityLogs; 