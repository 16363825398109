import React, { useState } from "react";
import {
  Box,
  useTheme,
  Typography,
  Autocomplete,
  TextField,
  Button,
  Stack
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Header from "components/Header";
import { useGetLevelHistoryQuery, useGetCustomersQuery } from "state/api";
import { formatDateTime } from "utils/formatDate";

const LevelHistory = () => {
  const theme = useTheme();
  const [selectedUser, setSelectedUser] = useState(null);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);

  const { data: customers } = useGetCustomersQuery();
  const { data, isLoading } = useGetLevelHistoryQuery({
    page,
    limit: pageSize,
    userId: selectedUser?._id
  });

  const columns = [
    {
      field: "userId",
      headerName: "User",
      flex: 1,
      valueGetter: (params) => 
        `${params.row.userId?.name} (${params.row.userId?.email})` || "N/A",
    },
    {
      field: "categorySlug",
      headerName: "Category",
      flex: 0.5,
      valueGetter: (params) => {
        const slug = params.row.categorySlug;
        return slug.split('_').map(word => 
          word.charAt(0).toUpperCase() + word.slice(1)
        ).join(' ');
      }
    },
    {
      field: "previousLevel",
      headerName: "Previous Level",
      flex: 0.5,
      type: 'number',
    },
    {
      field: "updatedLevel",
      headerName: "New Level",
      flex: 0.5,
      type: 'number',
    },
    {
      field: "updatedBy",
      headerName: "Updated By",
      flex: 1,
      valueGetter: (params) => 
        `${params.row.updatedBy?.name} (${params.row.updatedBy?.email})` || "N/A",
    },
    {
      field: "reason",
      headerName: "Reason",
      flex: 1,
    },
    {
      field: "createdAt",
      headerName: "Updated At",
      flex: 0.7,
      valueGetter: (params) => formatDateTime(params.row.createdAt),
    },
  ];

  const handleUserChange = (event, newValue) => {
    setSelectedUser(newValue);
    setPage(1); // Reset to first page when filter changes
  };

  const handleClearFilter = () => {
    setSelectedUser(null);
    setPage(1);
  };

  return (
    <Box m="1.5rem 2.5rem">
      <Header title="Level Update History" subtitle="Track all level changes" />
      
      {/* Filter Section */}
      <Stack 
        direction="row" 
        spacing={2} 
        sx={{ mb: 3, mt: 2 }}
        alignItems="center"
      >
        <Autocomplete
          sx={{ width: 300 }}
          options={customers || []}
          getOptionLabel={(option) => `${option.name} (${option.email})`}
          value={selectedUser}
          onChange={handleUserChange}
          renderInput={(params) => (
            <TextField 
              {...params} 
              label="Filter by Customer"
              variant="outlined"
              size="small"
            />
          )}
        />
        {selectedUser && (
          <Button 
            variant="outlined" 
            color="secondary"
            onClick={handleClearFilter}
            size="small"
          >
            Clear Filter
          </Button>
        )}
      </Stack>

      <Box
        mt="20px"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.primary.light,
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderTop: "none",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },
        }}
      >
        <DataGrid
          loading={isLoading || !data}
          getRowId={(row) => row._id}
          rows={data?.data || []}
          columns={columns}
          components={{
            Toolbar: GridToolbar,
          }}
          paginationModel={{ page: page - 1, pageSize }}
          onPaginationModelChange={(model) => {
            setPage(model.page + 1);
            setPageSize(model.pageSize);
          }}
          pageSizeOptions={[10, 25, 50, 100]}
          rowCount={data?.pagination?.total || 0}
          paginationMode="server"
        />
      </Box>
    </Box>
  );
};

export default LevelHistory; 