import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  TextField,
  useTheme,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Grid,
  Paper,
} from "@mui/material";
import Header from "components/Header";
import { useNavigate, useLocation } from 'react-router-dom';
import { useUpdateCustomerMutation } from "state/api";

const EditCustomer = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const user = location.state;
  
  const [updateCustomer] = useUpdateCustomerMutation();

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    password: '',
    organization: '',
    country: '',
    city: '',
    address: '',
    status: '',
    occupation: '',
    education: '',
    website: '',
    bio: ''
  });

  useEffect(() => {
    if (user) {
      setFormData({
        name: user.name || '',
        email: user.email || '',
        phone: user.phoneNumber || '',
        password: '',
        organization: user.organization || '',
        country: user.country?.name || '',
        city: user.city || '',
        address: user.address || '',
        status: user.status || 'Active',
        occupation: user.occupation || '',
        education: user.education || '',
        website: user.website || '',
        bio: user.bio || ''
      });
    }
  }, [user]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async () => {
    try {
      await updateCustomer({
        userId: user._id,
        ...formData
      }).unwrap();
      navigate('/customers');
    } catch (error) {
      console.error('Failed to update customer:', error);
    }
  };

  const statusList = ['Active', 'Inactive', 'Pending', 'Suspended'];
  const occupationList = ['Student', 'Teacher', 'Doctor', 'Engineer', 'Business', 'Other'];
  const countryList = ['Bangladesh', 'India', 'USA', 'UK', 'Canada', 'Australia'];
  const educationList = ['High School', 'Bachelor', 'Masters', 'PhD', 'Other'];

  return (
    <Box m="1.5rem 2.5rem">
      <Header title="EDIT USER" subtitle="Update User Information" />

      <Grid 
        container 
        spacing={3} 
        justifyContent="center" 
        sx={{ mt: 2 }}
      >
        <Grid item xs={12} md={8}>
          <Paper
            elevation={3}
            sx={{
              p: 3,
              backgroundColor: theme.palette.background.alt,
              borderRadius: "0.55rem",
            }}
          >
            <Grid container spacing={2}>
              {/* Basic Information */}
              <Grid item xs={12} sm={6}>
                <TextField
                  name="name"
                  label="Full Name"
                  value={formData.name}
                  onChange={handleChange}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="email"
                  label="Email"
                  type="email"
                  value={formData.email}
                  onChange={handleChange}
                  fullWidth
                  required
                />
              </Grid>

              {/* Contact Information */}
              <Grid item xs={12} sm={6}>
                <TextField
                  name="phone"
                  label="Phone Number"
                  value={formData.phone}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="password"
                  label="New Password"
                  type="password"
                  value={formData.password}
                  onChange={handleChange}
                  fullWidth
                  helperText="Leave blank to keep current password"
                />
              </Grid>

              {/* Organization & Location */}
              <Grid item xs={12} sm={6}>
                <TextField
                  name="organization"
                  label="Organization/Institute"
                  value={formData.organization}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel>Country</InputLabel>
                  <Select
                    name="country"
                    value={formData.country}
                    label="Country"
                    onChange={handleChange}
                  >
                    {countryList.map((item) => (
                      <MenuItem key={item} value={item}>{item}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              {/* Additional Details */}
              <Grid item xs={12} sm={6}>
                <TextField
                  name="city"
                  label="City"
                  value={formData.city}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel>Status</InputLabel>
                  <Select
                    name="status"
                    value={formData.status}
                    label="Status"
                    onChange={handleChange}
                  >
                    {statusList.map((item) => (
                      <MenuItem key={item} value={item}>{item}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              {/* Professional Information */}
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel>Occupation</InputLabel>
                  <Select
                    name="occupation"
                    value={formData.occupation}
                    label="Occupation"
                    onChange={handleChange}
                  >
                    {occupationList.map((item) => (
                      <MenuItem key={item} value={item}>{item}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel>Education</InputLabel>
                  <Select
                    name="education"
                    value={formData.education}
                    label="Education"
                    onChange={handleChange}
                  >
                    {educationList.map((item) => (
                      <MenuItem key={item} value={item}>{item}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              {/* Additional Fields */}
              <Grid item xs={12}>
                <TextField
                  name="website"
                  label="Website"
                  value={formData.website}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="bio"
                  label="Bio"
                  value={formData.bio}
                  onChange={handleChange}
                  multiline
                  rows={3}
                  fullWidth
                />
              </Grid>

              {/* Action Buttons */}
              <Grid item xs={12}>
                <Stack direction="row" spacing={2}>
                  <Button 
                    color="secondary"
                    variant="outlined"
                    onClick={() => navigate('/customers')}
                    fullWidth
                  >
                    Cancel
                  </Button>
                  <Button 
                    variant="contained" 
                    onClick={handleSubmit}
                    fullWidth
                    sx={{ 
                      backgroundColor: theme.palette.secondary[300],
                      color: theme.palette.background.alt,
                      '&:hover': {
                        backgroundColor: theme.palette.secondary[100],
                      }
                    }}
                  >
                    Update
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default EditCustomer;