import React, { useState } from "react";
import { Box, useTheme, Stack, Typography } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useGetMcqCompetitionsQuery } from "../../state/api";
import Header from "components/Header";

const McqCompetition = () => {
  const theme = useTheme();
  const { data: competitions, isLoading } = useGetMcqCompetitionsQuery();
  const [filteredRowCount, setFilteredRowCount] = useState(0);
  const [searchText, setSearchText] = useState("");

  const rows =
    competitions?.map((comp) => ({
      id: comp.competitionId || 123,
      category: comp.category || "Unknown",
      categorySlug: comp.categorySlug,
      level: comp.level,
      participants: comp.participants?.map((p) => p?.name || "Unknown").join(", "),
      questionsCount: comp?.questions?.length || 10,
      status: comp.status,
      duration: comp.duration,
      winner: comp.winnerUserId?.name || "N/A",
      looser: comp.looserUserId?.name || "N/A",
      startTime: comp.startTime ? new Date(comp.startTime).toLocaleString() : "N/A",
    })) || [];

  const columns = [
    { field: "category", headerName: "Category", flex: 1, filterable: true },
    { field: "level", headerName: "Level", flex: 1, filterable: true },
    { field: "participants", headerName: "Participants", flex: 2, filterable: true },
    { field: "status", headerName: "Status", flex: 1, filterable: true },
    { field: "duration", headerName: "Duration (s)", flex: 1, filterable: true },
    { field: "winner", headerName: "Winner", flex: 1, filterable: true },
    { field: "looser", headerName: "Looser", flex: 1, filterable: true },
    { field: "startTime", headerName: "Start Time", flex: 1, filterable: true },
  ];

  // Global search filter
  const filteredData = rows.filter(row => {
    if (!searchText) return true;
    const searchLower = searchText.toLowerCase();
    return (
      row.category?.toLowerCase().includes(searchLower) ||
      row.participants?.toLowerCase().includes(searchLower) ||
      row.winner?.toLowerCase().includes(searchLower) ||
      row.looser?.toLowerCase().includes(searchLower)
    );
  });

  return (
    <Box sx={{ padding: { xs: 1, md: 3 } }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" flexWrap="wrap" gap={2}>
        <Header title="MCQ Competitions" subtitle="View all MCQ competitions and their details" />
        
        {/* Search and Stats Section */}
        <Stack direction="row" spacing={2} alignItems="center">
          <Box>
            <Typography variant="subtitle2" color="text.secondary">
              Total Competitions: {rows.length}
            </Typography>
            <Typography variant="subtitle2" color="text.secondary">
              Filtered: {filteredRowCount || filteredData.length}
            </Typography>
          </Box>
        </Stack>
      </Box>

      <Box
        mt="20px"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.primary.light,
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderTop: "none",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },
        }}
      >
        <DataGrid
          loading={isLoading}
          getRowId={(row) => row.id}
          rows={filteredData}
          columns={columns}
          components={{
            Toolbar: GridToolbar,
          }}
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
          onFilterModelChange={(filterModel) => {
            const filteredRows = filterModel.items.length > 0 ? 
              filteredData.filter(row => {
                return filterModel.items.every(filter => {
                  const value = row[filter.columnField];
                  const filterValue = filter.value;
                  if (!value || !filterValue) return false;
                  return value.toString().toLowerCase().includes(filterValue.toLowerCase());
                });
              }) : 
              filteredData;
            setFilteredRowCount(filteredRows.length);
          }}
          initialState={{
            pagination: { paginationModel: { pageSize: 25 } },
          }}
          pageSizeOptions={[10, 25, 50, 100]}
        />
      </Box>
    </Box>
  );
};

export default McqCompetition;