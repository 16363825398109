import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  Grid,
  Typography,
  IconButton,
  OutlinedInput,
  Chip,
  InputAdornment,
  ListSubheader,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useCreateAdMutation, useUpdateAdMutation } from 'state/api';
import { countries } from 'utils/countries';
import { GridSearchIcon } from '@mui/x-data-grid';
import { toast } from 'react-toastify';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const AdForm = ({ open, onClose, ad }) => {
  const [formData, setFormData] = useState({
    adTitle: '',
    adDescription: '',
    adType: '',
    adFileType: '',
    adFile: null,
    adLink: '',
    viewLimit: '',
    duration: '',
    cost: '',
    paymentStatus: 'Unpaid',
    allowCountries: ['ALL'],
    status: 'Active'
  });
  const [filePreview, setFilePreview] = useState(null);
  const [searchText, setSearchText] = useState('');

  const [createAd] = useCreateAdMutation();
  const [updateAd] = useUpdateAdMutation();

  useEffect(() => {
    if (ad) {
      setFormData({
        adTitle: ad.adTitle || '',
        adDescription: ad.adDescription || '',
        adType: ad.adType || '',
        adFileType: ad.adFileType || '',
        adLink: ad.adLink || '',
        viewLimit: ad.viewLimit || '',
        duration: ad.duration || '',
        cost: ad.cost || '',
        paymentStatus: ad.paymentStatus || 'Unpaid',
        allowCountries: ad.allowCountries || ['ALL'],
        status: ad.status || 'Active',
        adFile: null
      });
      setFilePreview(ad.adFile ? `${process.env.REACT_APP_API_URL}/img/${ad.adFile}` : null);
    }
  }, [ad]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    
    if (name === 'allowCountries') {
      let processedValue = value;
      
      // If ALL is selected, clear other selections
      if (Array.isArray(value)) {
        if (value.includes('ALL')) {
          processedValue = ['ALL'];
        } else if (formData.allowCountries.includes('ALL')) {
          // If ALL was previously selected, remove it
          processedValue = value.filter(v => v !== 'ALL');
        }
      }
      
      setFormData(prev => ({
        ...prev,
        [name]: processedValue
      }));
    } else {
      setFormData(prev => ({
        ...prev,
        [name]: value
      }));
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFormData(prev => ({
        ...prev,
        adFile: file
      }));
      setFilePreview(URL.createObjectURL(file));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      const formDataToSend = new FormData();
      
      // Append all form fields
      Object.keys(formData).forEach(key => {
        if (key === 'allowCountries') {
          formDataToSend.append(key, JSON.stringify(formData[key]));
        } else if (key === 'adFile' && formData[key]) {
          formDataToSend.append('adFile', formData[key]);
        } else if (formData[key] !== null) {
          formDataToSend.append(key, formData[key]);
        }
      });

      if (ad?.id) {
        await updateAd({
          id: ad.id,
          formData: formDataToSend
        }).unwrap();
        toast.success('Ad updated successfully!');
      } else {
        await createAd(formDataToSend).unwrap();
        toast.success('Ad created successfully!');
      }
      
      onClose();
    } catch (error) {
      console.error('Error submitting ad:', error);
      toast.error(error.data.message);
    }
  };

  const filteredCountries = countries.filter(country => 
    country.name.toLowerCase().includes(searchText.toLowerCase())
  );

  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6">
            {ad ? 'Edit Ad' : 'Create New Ad'}
          </Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                name="adTitle"
                label="Ad Title"
                fullWidth
                value={formData.adTitle}
                onChange={handleChange}
                required
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                name="adDescription"
                label="Ad Description"
                fullWidth
                multiline
                rows={3}
                value={formData.adDescription}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel>Ad Type</InputLabel>
                <Select
                  name="adType"
                  value={formData.adType}
                  onChange={handleChange}
                  label="Ad Type"
                >
                  <MenuItem value="Image">Image</MenuItem>
                  <MenuItem value="Video">Video</MenuItem>
                  <MenuItem value="Slider">Slider</MenuItem>
                  <MenuItem value="Popup">Popup</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel>Ad File Type</InputLabel>
                <Select
                  name="adFileType"
                  value={formData.adFileType}
                  onChange={handleChange}
                  label="Ad File Type"
                >
                  <MenuItem value="Image">Image</MenuItem>
                  <MenuItem value="Video">Video</MenuItem>
                  <MenuItem value="Google">Google</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <TextField
                type="file"
                onChange={handleFileChange}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
              />
              {filePreview && (
                <Box mt={2} display="flex" justifyContent="center">
                  {formData.adFileType === 'Video' ? (
                    <video src={filePreview} controls width="300" />
                  ) : (
                    <img src={filePreview} alt="Preview" style={{ maxWidth: '300px' }} />
                  )}
                </Box>
              )}
            </Grid>

            <Grid item xs={12}>
              <TextField
                name="adLink"
                label="Ad Link"
                fullWidth
                value={formData.adLink}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                name="viewLimit"
                label="View Limit"
                type="number"
                fullWidth
                value={formData.viewLimit}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                name="duration"
                label="Duration"
                type="number"
                fullWidth
                value={formData.duration}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                name="cost"
                label="Cost"
                type="number"
                fullWidth
                value={formData.cost}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel>Payment Status</InputLabel>
                <Select
                  name="paymentStatus"
                  value={formData.paymentStatus}
                  onChange={handleChange}
                  label="Payment Status"
                >
                  <MenuItem value="Paid">Paid</MenuItem>
                  <MenuItem value="Unpaid">Unpaid</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel>Allow Countries</InputLabel>
                <Select
                  multiple
                  name="allowCountries"
                  value={formData.allowCountries}
                  onChange={handleChange}
                  input={<OutlinedInput label="Allow Countries" />}
                  renderValue={(selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      {selected.map((value) => (
                        <Chip 
                          key={value} 
                          label={value === 'ALL' ? 'All Countries' : countries.find(c => c.code === value)?.name || value} 
                        />
                      ))}
                    </Box>
                  )}
                  MenuProps={MenuProps}
                >
                  <ListSubheader>
                    <TextField
                      size="small"
                      autoFocus
                      placeholder="Type to search..."
                      fullWidth
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <GridSearchIcon />
                          </InputAdornment>
                        )
                      }}
                      onChange={(e) => setSearchText(e.target.value)}
                      onKeyDown={(e) => {
                        if (e.key !== 'Escape') {
                          e.stopPropagation();
                        }
                      }}
                    />
                  </ListSubheader>
                  <MenuItem value="ALL">All Countries</MenuItem>
                  {filteredCountries.map((country) => (
                    <MenuItem
                      key={country.code}
                      value={country.code}
                      disabled={formData.allowCountries.includes('ALL')}
                    >
                      {country.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel>Status</InputLabel>
                <Select
                  name="status"
                  value={formData.status}
                  onChange={handleChange}
                  label="Status"
                >
                  <MenuItem value="Active">Active</MenuItem>
                  <MenuItem value="Inactive">Inactive</MenuItem>
                  <MenuItem value="Done">Done</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="secondary">
            Cancel
          </Button>
          <Button type="submit" variant="contained" color="primary">
            {ad ? 'Update' : 'Create'}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AdForm; 