import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useGetUserPermissionsQuery } from 'state/api';
import { CircularProgress, Box } from '@mui/material';

const AccessControl = ({ children, requiredPermission }) => {
  const user = useSelector(state => state.auth.user);
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  const [hasPermission, setHasPermission] = useState(false);
  const [loading, setLoading] = useState(!!requiredPermission);
  
  // Skip permission check if no specific permission is required or for superadmins
  const skipPermissionCheck = !requiredPermission || user?.role === 'superadmin';
  
  const { data: permissionsResponse, isLoading } = useGetUserPermissionsQuery(
    user?._id, 
    { skip: !isAuthenticated || !user || skipPermissionCheck }
  );

  
  useEffect(() => {
    // If no specific permission is required or user is superadmin
    if (skipPermissionCheck) {
      setHasPermission(true);
      setLoading(false);
      return;
    }

    
    if (!isLoading && permissionsResponse?.data) {
      // Check if user has the required permission in the permissions array
      // The permissions structure comes from the permissions table
      const userPermissions = permissionsResponse.data.permissions || [];
      setHasPermission(userPermissions.includes(requiredPermission));
      setLoading(false);
    } else if (!isLoading) {
      // If data is loaded but no permissions found
      setHasPermission(false);
      setLoading(false);
    }
  }, [user, requiredPermission, permissionsResponse, isLoading, skipPermissionCheck]);
  
  // Show loading indicator while checking permissions
  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }
  
  // Redirect if not authenticated
  if (!isAuthenticated) {
    return <Navigate to="/securelogin" />;
  }
  
  // Redirect if doesn't have permission (but is authenticated)
  if (requiredPermission && !hasPermission) {
    return <Navigate to="/dashboard" />;
  }
  
  return children;
};

export default AccessControl; 