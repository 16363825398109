import React, { useState, useEffect } from "react";
import {
  Box,
  Card,
  Typography,
  useTheme,
  useMediaQuery,
  Grid,
  Divider,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  useNavigate,
} from "@mui/material";
import Header from "components/Header";
import { useLocation, useNavigate as useRouterNavigate } from 'react-router-dom';
import { useGetReferralUsersQuery } from "state/api";

const User = () => {
  const theme = useTheme();
  const location = useLocation();
  const navigate = useRouterNavigate();
  const isNonMobile = useMediaQuery("(min-width: 1000px)");
  const user = location.state;

  // Pagination state
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  // Fetch referral users
  const { data: referralData, isLoading } = useGetReferralUsersQuery({
    userId: user._id,
    page: page + 1,
    limit: rowsPerPage
  });

  // console.log('referralData', referralData);

  const handleLevelClick = (game, level) => {
    navigate('/category/level/user/history', {
      state: {
        userId: user._id,
        categorySlug: game,
        level: level,
        userName: user.name
      }
    });
  };

  const UserInfoCard = ({ title, children }) => (
    <Paper 
      elevation={2}
      sx={{
        backgroundColor: theme.palette.background.alt,
        p: 3,
        mb: 3,
        borderRadius: "0.55rem"
      }}
    >
      <Typography variant="h6" color={theme.palette.secondary[100]} mb={2}>
        {title}
      </Typography>
      {children}
    </Paper>
  );

  const InfoRow = ({ label, value }) => (
    <Box display="flex" mb={1}>
      <Typography variant="body1" color={theme.palette.secondary[100]} width="150px">
        {label}:
      </Typography>
      <Typography variant="body1" color={theme.palette.secondary[200]}>
        {value || "N/A"}
      </Typography>
    </Box>
  );

  const ReferralUsersTable = () => (
    <TableContainer component={Paper} sx={{ backgroundColor: theme.palette.background.alt }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={{ color: theme.palette.secondary[100] }}>Name</TableCell>
            <TableCell sx={{ color: theme.palette.secondary[100] }}>Email</TableCell>
            <TableCell sx={{ color: theme.palette.secondary[100] }}>Phone</TableCell>
            <TableCell sx={{ color: theme.palette.secondary[100] }}>Country</TableCell>
            <TableCell sx={{ color: theme.palette.secondary[100] }}>Joined On</TableCell>
            <TableCell sx={{ color: theme.palette.secondary[100] }}>Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoading ? (
            <TableRow>
              <TableCell colSpan={6} align="center">Loading...</TableCell>
            </TableRow>
          ) : referralData?.data?.refUsers?.length ? (
            referralData?.data?.refUsers?.map((refUser) => (
              <TableRow key={refUser._id}>
                <TableCell sx={{ color: theme.palette.secondary[200] }}>{refUser.name}</TableCell>
                <TableCell sx={{ color: theme.palette.secondary[200] }}>{refUser.email}</TableCell>
                <TableCell sx={{ color: theme.palette.secondary[200] }}>{refUser.phone}</TableCell>
                <TableCell sx={{ color: theme.palette.secondary[200] }}>{refUser.country?.name}</TableCell>
                <TableCell sx={{ color: theme.palette.secondary[200] }}>{refUser.joinDateFormat}</TableCell>
                <TableCell sx={{ color: theme.palette.secondary[200] }}>{refUser.status}</TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={6} align="center">No referral users found</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 100]}
        component="div"
        count={referralData?.data?.pagination?.total || 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={(event, newPage) => setPage(newPage)}
        onRowsPerPageChange={(event) => {
          setRowsPerPage(parseInt(event.target.value, 10));
          setPage(0);
        }}
        sx={{
          color: theme.palette.secondary[100],
          ".MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows": {
            color: theme.palette.secondary[100]
          }
        }}
      />
    </TableContainer>
  );

  return (
    <Box sx={{ padding: { xs: 1, md: 3 } }}>
      <Header title="USER PROFILE" subtitle="Detailed User Information" />
      
      <Grid container spacing={3} mt={1}>
        <Grid item xs={12} md={6}>
          <UserInfoCard title="Basic Information">
            <InfoRow label="Name" value={user.name} />
            <InfoRow label="Email" value={user.email} />
            <InfoRow label="Phone" value={user.phone} />
            <InfoRow label="Organization" value={user.organization} />
            <InfoRow label="Rank" value={user.rank} />
            <InfoRow label="Status" value={user.status} />
          </UserInfoCard>
        </Grid>

        <Grid item xs={12} md={6}>
          <UserInfoCard title="Location & Timing">
            <InfoRow label="Country" value={user.country?.name} />
            <InfoRow label="City" value={user.country?.city} />
            <InfoRow label="Joined On" value={user.createdAtFormat} />
            <InfoRow label="Last Updated" value={user.updatedAtFormat} />
            <InfoRow label="Time Zone" value={user.country?.timezone} />
          </UserInfoCard>
        </Grid>

        <Grid item xs={12} md={6}>
          <UserInfoCard title="Game Statistics">
            <InfoRow label="Coin Balance" value={user.coinBalance} />
            <InfoRow label="Total Referrals" value={user.myRefTotal} />
            <InfoRow 
              label="Referred By" 
              value={user.refUserId ? `${user.refUserId.name} (${user.refUserId.email})` : 'None'} 
            />
          </UserInfoCard>
        </Grid>

        <Grid item xs={12}>
          <UserInfoCard title="Level Information">
            <Grid container spacing={2}>
              {Object.entries(user.levels || {}).map(([game, level], index) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <Card 
                    sx={{ 
                      p: 2,
                      backgroundColor: theme.palette.primary.light,
                      height: '100%',
                      cursor: 'pointer',
                      '&:hover': {
                        opacity: 0.9,
                        transform: 'scale(1.02)',
                        transition: 'all 0.3s'
                      }
                    }}
                    onClick={() => handleLevelClick(game, level)}
                  >
                    <Typography variant="h6" color={theme.palette.secondary[100]}>
                      {game.replace(/_/g, ' ').toUpperCase()}
                    </Typography>
                    <Typography 
                      variant="h3" 
                      color={theme.palette.secondary[200]}
                      textAlign="center"
                      mt={1}
                    >
                      {level}
                    </Typography>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </UserInfoCard>
        </Grid>

        <Grid item xs={12}>
          <UserInfoCard title={`Users Referred By ${user.name} (${referralData?.data?.pagination?.count || 0})`}>
            <ReferralUsersTable />
          </UserInfoCard>
        </Grid>
      </Grid>
    </Box>
  );
};

export default User;