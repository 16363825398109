import React,{ useState, useEffect } from "react";
import {
  Box,
  Card,
  CardActions,
  CardContent,
  Collapse,
  Button,
  Typography,
  Rating,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { useGetCustomersQuery,useGetHistoryByUserAndSlugQuery,useGetProductsQuery } from "state/api";
import Header from "components/Header";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate, useLocation } from 'react-router-dom'
import { use } from "react";
// import BackButton from "components/BackButton";

const CategoryLevelUserHistory = () => {
  const theme    = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const {userName, userId, categoryName, categorySlug, level} = location.state;
  const {data:response, isLoading} = useGetHistoryByUserAndSlugQuery({userId, categorySlug});
  const isNonMobile = useMediaQuery("(min-width: 1000px)");
  const [historyType, setHistoryType] = useState();

  const history = response?.data;
  console.log("history", history);

  useEffect(() => {
    if (['tic_tac_toe', 'ludu', 'chess', 'carram'].includes(categorySlug)) {
      setHistoryType('Game');
    } else {
      setHistoryType('MCQ');
    }
  }, [categorySlug]);


  return (
    <Box m="1.5rem 2.5rem">
      {/* <BackButton path='/customers' /> */}
      <Header title={`History of ${userName}`} subtitle={`listing all the competition history from level ${level} and ${categorySlug.replace(/_/g, ' ').toUpperCase()}`} />
      <Box
        mt="40px"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.primary.light,
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderTop: "none",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },
        }}
      >
        

        <div className="container-fluid mt-5">
          <div className="row mt-3">
            {isLoading ? (
              <Typography variant="h6" color="textSecondary">Loading...</Typography>
            ) : Array.isArray(history) && history.length > 0 ? (
              history.map((item, index) => (
                <div className="col-md-12 mb-3" key={index}>
                  <div className="card shadow-sm" style={{ backgroundColor: theme.palette.background.alt, color: theme.palette.text.primary }}>
                    
                    { historyType === 'MCQ' ? <div className="card-body">
                      <h5 className="card-title text-center border-bottom mb-3 pb-3">Status: {item.status}  | Level: {item.level} | Category: {item.category} | Time: {item.startTimeFormat} <br/>
                      Competition Id: {item.competitionId}
                      </h5>
                      {item.status === 'completed' && item.winnerUserId && item.looserUserId && (
                        <div className="row">
                          <div className="col-md-6 text-center">
                            <div className="card-subtitle">
                              Winner: 
                              <div><img src={`${item.winnerUserId?.image}`} className="rounded-circle" width="80" height="80" /></div>
                              <div>{item.winnerUserId?.name}</div>
                              <div className="px-1">{item.winnerUserId?.email}</div>
                              <div className="px-1">Duration: {item.winnerUserId?.duration} sec</div>
                              <div className="px-1">Correct Answers: {item.winnerUserId?.correctAnswers}</div>
                              <div className="px-1">Current Level: {item.winnerUserId?.levels?.[categorySlug]}</div>
                              <div className="px-1">{item.winnerUserId?.country?.name}</div>
                            </div>
                          </div>
                          <div className="col-md-6 text-center">
                            <div className="card-subtitle">
                              Loser: 
                              <div><img src={`${item.looserUserId?.image}`} className="rounded-circle" width="80" height="80" /></div>
                              <div>{item.looserUserId?.name}</div>
                              <div className="px-1">{item.looserUserId?.email}</div>
                              <div className="px-1">Duration: {item.looserUserId?.duration} sec</div>
                              <div className="px-1">Correct Answers: {item.looserUserId?.correctAnswers}</div>
                              <div className="px-1">Current Level: 1</div>
                              <div className="px-1">{item.looserUserId?.country?.name}</div>
                            </div>
                          </div>
                        </div>
                      )}

                      {( (item.status !== 'completed') && ( item.participants?.[0] || item.participants?.[1]) ) && (
                        <div className="row">
                          <div className="col-md-6 text-center">
                            <div className="card-subtitle">
                              Participant 1: 
                              <div><img src={`${item.participants?.[0]?.image}`} className="rounded-circle" width="80" height="80" /></div>
                              <div>{item.participants?.[0]?.name}</div>
                              <div className="px-1">{item.participants?.[0]?.email}</div>
                              <div className="px-1">Duration: {item.participants?.[0]?.duration} sec</div>
                              <div className="px-1">Correct Answers: {item.participants?.[0]?.correctAnswers}</div>
                              <div className="px-1">Current Level: {item.participants?.[0]?.levels?.[categorySlug]}</div>
                              <div className="px-1">{item.participants?.[0]?.country?.name}</div>
                            </div>
                          </div>
                          <div className="col-md-6 text-center">
                            <div className="card-subtitle">
                              Participant 2: 
                              <div><img src={`${item.participants?.[1]?.image}`} className="rounded-circle" width="80" height="80" /></div>
                              <div>{item.participants?.[1]?.name}</div>
                              <div className="px-1">{item.participants?.[1]?.email}</div>
                              <div className="px-1">Duration: {item.participants?.[1]?.duration} sec</div>
                              <div className="px-1">Correct Answers: {item.participants?.[1]?.correctAnswers}</div>
                              <div className="px-1">Current Level: {item.participants?.[1]?.levels?.[categorySlug]}</div>
                              <div className="px-1">{item.participants?.[1]?.country?.name}</div>
                            </div>
                          </div>
                        </div>
                      )}

                    </div>
                    :
                    <div className="card-body">
                      <h5 className="card-title text-center border-bottom mb-3 pb-3">Status: Complete  | Level: {item.level} | Category: {item.categorySlug} | Time: {item.dateTimeFormat} <br /> GameID: {item.gameId}</h5>

                        <div className="row">
                          <div className="col-md-6 text-center">
                            <div className="card-subtitle">
                              Winner: 
                              <div><img src={`${item.winnerUserId?.image}`} className="rounded-circle" width="80" height="80" /></div>
                              <div>{item.winnerUserId?.name}</div>
                              <div className="px-1">{item.winnerUserId?.email}</div>
                              <div className="px-1">Current Level: {item?.winnerLevel}</div>
                              <div className="px-1">{item.winnerUserId?.country?.name}</div>
                            </div>
                          </div>
                          <div className="col-md-6 text-center">
                            <div className="card-subtitle">
                              Loser: 
                              <div><img src={`${item.looserUserId?.image}`} className="rounded-circle" width="80" height="80" /></div>
                              <div>{item.looserUserId?.name}</div>
                              <div className="px-1">{item.looserUserId?.email}</div>
                              <div className="px-1">Current Level: {item?.looserLevel}</div>
                              <div className="px-1">{item.looserUserId?.country?.name}</div>
                            </div>
                          </div>
                        </div>
               

                    </div>
    }


                  </div>
                </div>
              ))
            ) : (
              <Typography variant="h6" color="textSecondary">No history found.</Typography>
            )}
          </div>
        </div>

      </Box>
    </Box>
  );
};

export default CategoryLevelUserHistory;