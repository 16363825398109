import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  TextField,
  Typography,
  IconButton,
  Box,
  Alert,
  Snackbar
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useUpdateUserLevelsMutation } from 'state/api';

const GAME_CATEGORIES = [
  { slug: 'general_mcq', name: 'General MCQ' },
  { slug: 'math_mcq', name: 'Math MCQ' },
  { slug: 'english_mcq', name: 'English MCQ' },
  { slug: 'islamic_mcq', name: 'Islamic MCQ' },
  { slug: 'science_mcq', name: 'Science MCQ' },
  { slug: 'sports_mcq', name: 'Sports MCQ' },
  { slug: 'chess', name: 'Chess' },
  { slug: 'carram', name: 'Carrom' },
  { slug: 'tic_tac_toe', name: 'Tic Tac Toe' },
  { slug: 'ludo', name: 'Ludo' }
];

const EditLevelsModal = ({ open, onClose, user }) => {
  const [levels, setLevels] = useState({});
  const [reason, setReason] = useState('');
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [updateUserLevels, { isLoading }] = useUpdateUserLevelsMutation();

  useEffect(() => {
    if (user?.levels) {
      setLevels(user.levels);
    }
  }, [user]);

  const handleChange = (categorySlug, value) => {
    setLevels(prev => ({
      ...prev,
      [categorySlug]: Math.max(1, Math.min(25, parseInt(value) || 1))
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const result = await updateUserLevels({
        userId: user._id,
        levels,
        reason
      }).unwrap();

      setSnackbar({
        open: true,
        message: 'Levels updated successfully',
        severity: 'success'
      });
      
      setTimeout(() => {
        onClose();
      }, 1000);

    } catch (error) {
      console.error('Error updating levels:', error);
      setSnackbar({
        open: true,
        message: error.data?.message || 'Failed to update levels',
        severity: 'error'
      });
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbar(prev => ({ ...prev, open: false }));
  };

  if (!user) return null;

  return (
    <>
      <Dialog 
        open={open} 
        onClose={onClose}
        maxWidth="md"
        fullWidth
      >
        <form onSubmit={handleSubmit}>
          <DialogTitle>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Typography variant="h6">
                Edit Levels - {user.name} - {user.email}
              </Typography>
              <IconButton onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent dividers>
            <Grid container spacing={2}>
              {GAME_CATEGORIES.map(category => (
                <Grid item xs={12} sm={6} key={category.slug}>
                  <TextField
                    fullWidth
                    label={category.name}
                    type="number"
                    value={levels[category.slug] || 1}
                    onChange={(e) => handleChange(category.slug, e.target.value)}
                    inputProps={{ min: 1, max: 25 }}
                  />
                </Grid>
              ))}
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Reason for Update"
                  multiline
                  rows={2}
                  value={reason}
                  onChange={(e) => setReason(e.target.value)}
                  required
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button 
              onClick={onClose} 
              color="error"
              disabled={isLoading}
            >
              Cancel
            </Button>
            <Button 
              type="submit" 
              variant="contained" 
              color="primary"
              disabled={isLoading}
            >
              {isLoading ? 'Updating...' : 'Update Levels'}
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert 
          onClose={handleCloseSnackbar} 
          severity={snackbar.severity}
          variant="filled"
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default EditLevelsModal; 