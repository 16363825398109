import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Typography,
  CircularProgress,
  Box,
  Divider,
  IconButton,
  useTheme
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { useGetPermissionItemsQuery, useGetUserPermissionsQuery, useUpdateUserPermissionsMutation, useInitializePermissionItemsMutation } from 'state/api';
import { toast } from 'react-toastify';

const AdminPermissionsModal = ({ open, onClose, admin }) => {
  const theme = useTheme();
  const [selectedPermissions, setSelectedPermissions] = useState([]);
  
  const { data: permissionItems, isLoading: itemsLoading } = useGetPermissionItemsQuery();
  const { data: userPermissions, isLoading: userPermissionsLoading } = useGetUserPermissionsQuery(
    admin?._id, 
    { skip: !admin }
  );
  const [updatePermissions, { isLoading: isUpdating }] = useUpdateUserPermissionsMutation();
  const [initializePermissionItems] = useInitializePermissionItemsMutation();
  
  // Initialize default permissions if needed
  useEffect(() => {
    if (permissionItems?.data?.length === 0) {
      initializePermissionItems();
    }
  }, [permissionItems]);
  
  useEffect(() => {
    if (userPermissions?.data?.permissions) {
      setSelectedPermissions(userPermissions.data.permissions);
    } else {
      setSelectedPermissions([]);
    }
  }, [userPermissions]);
  
  const handlePermissionChange = (permission) => {
    setSelectedPermissions(prev => {
      if (prev.includes(permission)) {
        return prev.filter(p => p !== permission);
      } else {
        return [...prev, permission];
      }
    });
  };
  
  const handleSelectAll = () => {
    const allPermissions = permissionItems?.data
      .filter(item => item.isActive)
      .map(item => item.name) || [];
      
    setSelectedPermissions(allPermissions);
  };
  
  const handleDeselectAll = () => {
    setSelectedPermissions([]);
  };
  
  const handleSave = async () => {
    try {
      await updatePermissions({
        userId: admin._id,
        permissions: selectedPermissions
      }).unwrap();
      
      toast.success('Permissions updated successfully');
      onClose();
    } catch (error) {
      toast.error(error?.data?.message || 'Failed to update permissions');
    }
  };
  
  const isLoading = itemsLoading || userPermissionsLoading;
  
  // Default permissions to show if no permission items exist yet
  const defaultPermissions = [
    { name: 'manageQuestions', description: 'Manage Questions' },
    { name: 'manageAds', description: 'Manage Ads' },
    { name: 'viewGameHistory', description: 'View Game History' },
    { name: 'viewMcqHistory', description: 'View MCQ History' },
    { name: 'viewLevelChangeHistory', description: 'View Level Change History' },
    { name: 'viewCoinHistory', description: 'View Coin History' },
    { name: 'manageNotifications', description: 'Manage Notifications' },
    { name: 'manageShopCategories', description: 'Manage Shop Categories' },
    { name: 'manageUsers', description: 'Manage Users' },
    { name: 'manageAdmins', description: 'Manage Admins' },
  ];
  
  const permissionsToDisplay = permissionItems?.data?.length > 0 
    ? permissionItems.data 
    : defaultPermissions;
  
  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography 
            variant="h6" 
            color={theme.palette.secondary[100]} // Use theme color for title
            fontWeight="bold"
          >
            Manage Permissions for {admin?.name}
          </Typography>
          <IconButton onClick={onClose} size="small">
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <Divider />
      <DialogContent sx={{ bgcolor: theme.palette.background.alt }}>
        {isLoading ? (
          <Box display="flex" justifyContent="center" p={3}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            <Box display="flex" justifyContent="flex-end" mb={2}>
              <Button 
                onClick={handleSelectAll} 
                sx={{ 
                  mr: 1,
                  color: theme.palette.secondary[200]
                }}
              >
                Select All
              </Button>
              <Button 
                onClick={handleDeselectAll}
                sx={{ color: theme.palette.secondary[200] }}
              >
                Deselect All
              </Button>
            </Box>
            <FormGroup>
              {permissionsToDisplay
                .filter(item => item.isActive !== false) // Only show active items
                .map(item => (
                  <FormControlLabel
                    key={item.name}
                    control={
                      <Checkbox
                        checked={selectedPermissions.includes(item.name)}
                        onChange={() => handlePermissionChange(item.name)}
                        sx={{
                          color: theme.palette.secondary[300],
                          '&.Mui-checked': {
                            color: theme.palette.secondary[100],
                          },
                        }}
                      />
                    }
                    label={
                      <Typography color={theme.palette.secondary[100]}>
                        {item.description}
                      </Typography>
                    }
                  />
                ))}
            </FormGroup>
          </>
        )}
      </DialogContent>
      <DialogActions sx={{ bgcolor: theme.palette.background.alt }}>
        <Button 
          onClick={onClose}
          sx={{ color: theme.palette.secondary[300] }}
        >
          Cancel
        </Button>
        <Button 
          onClick={handleSave} 
          variant="contained" 
          disabled={isUpdating}
          color="success"
          sx={{ fontWeight: 'bold' }}
        >
          {isUpdating ? 'Saving...' : 'Save Permissions'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AdminPermissionsModal; 