import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const api = createApi({
  // baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_API_URL }),
  baseQuery: fetchBaseQuery({ 
    baseUrl: process.env.REACT_APP_API_URL,
    prepareHeaders: (headers, { getState }) => {
      const token = getState()?.auth?.authToken
      console.log('token ', token);
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  reducerPath: "adminApi",
  tagTypes: [
    "User",
    "Products",
    "Customers",
    "Transactions",
    "Geography",
    "Sales",
    "Admins",
    "Performance",
    "Dashboard",
    "Teacher",
    "Catagory",
    "History",
    "Notification",
    "LevelUsers",
    "LevelHistory",
    "CoinTransactions",
    "Ads",
    "ShopCategories",
    "Permissions",
    "PermissionItems",
    "Activities",
  ],
  endpoints: (build) => ({
    getUser: build.query({
      query: (id) => `general/user/${id}`,
      providesTags: ["User"],
    }),
    getProducts: build.query({
      query: () => "client/products",
      providesTags: ["Products"],
    }),
    getCustomers: build.query({
      query: ({ page = 1, limit = 10, search = '', sortField = 'createdAt', sortOrder = 'desc' }) => ({
        url: 'client/customers',
        method: 'GET',
        params: { page, limit, search, sortField, sortOrder }
      }),
      providesTags: ["Customers"],
    }),
    getTransactions: build.query({
      query: ({ page, pageSize, sort, search }) => ({
        url: "client/transactions",
        method: "GET",
        params: { page, pageSize, sort, search },
      }),
      providesTags: ["Transactions"],
    }),
    getGeography: build.query({
      query: () => "client/geography",
      providesTags: ["Geography"],
    }),
    getSales: build.query({
      query: () => "sales/sales",
      providesTags: ["Sales"],
    }),
    getAdmins: build.query({
      query: () => "management/admins",
      providesTags: ["Admins"],
    }),
    getUserPerformance: build.query({
      query: (id) => `management/performance/${id}`,
      providesTags: ["Performance"],
    }),
    getDashboard: build.query({
      query: () => "general/dashboard",
      providesTags: ["Dashboard"],
    }),
    getCategory: build.query({
      query: () => "api/category",
      providesTags: ["Category"],
    }),
    getHistories: build.query({
      query: () => "api/history",
      providesTags: ["History"],
    }),
    getMcqCompetitions: build.query({
      query: () => "api/mcqcompetition",
      providesTags: ["History"],
    }),
    getHistoryByUserAndSlug: build.query({
      query: ({userId, categorySlug}) => `api/history/${userId}/${categorySlug}`,
      providesTags: ["History"],
    }),
    createPost: build.mutation({
      query: (newPost) => ({
        url: "api/register",
        method: "POST",
        body: newPost,
      }),
      invalidatesTags: ["Admins"],
    }),
    updateUser: build.mutation({
      query: ({ id, formData }) => ({
        url: `api/update/${id}`,
        method: "PUT",
        body: formData,
      }),
      invalidatesTags: ["Admins"],
    }),
    deleteUser: build.mutation({
      query: (id) => ({
        url: `api/delete/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Admins"],
    }), 
    login: build.mutation({
      query: ({ email, password }) => ({
        url: "api/login",
        method: "POST",
        body: { email, password },
      }),
    }),
    getTeacher: build.query({
      query: () => "general/teachers",
      providesTags: ["Teacher"],
    }),
    createTeacher: build.mutation({
      query: (newPost) => ({
        url: "general/teacher/add",
        method: "POST",
        body: newPost,
      }),
      invalidatesTags: ["Teacher"],
    }),
    updateTeacher: build.mutation({
      query: (formData) => ({
        url: `general/teacher/update/${formData._id}`,
        method: "PUT",
        body: formData,
      }),
      invalidatesTags: ["Teacher"],
    }),
    deleteTeacher: build.mutation({
      query: (id) => ({
        url: `api/teacher/delete/${id}`,
        method: "DELETE",
      }),
      // After deleting, invalidate user data or other cached data
      invalidatesTags: ["Teacher"],
    }),
    getNotifications: build.query({
      query: ({ page, limit, search, status, categorySlug, level }) => ({
        url: "api/notifications",
        method: "GET",
        params: { page, limit, search, status, categorySlug, level },
      }),
      providesTags: (result) => 
        result
          ? [
              ...result.data.map(({ _id }) => ({ type: 'Notification', id: _id })),
              { type: 'Notification', id: 'LIST' },
            ]
          : [{ type: 'Notification', id: 'LIST' }],
    }),
    createNotification: build.mutation({
      query: (formData) => ({
        url: "api/notifications",
        method: "POST",
        body: formData,
        formData: true,
      }),
      invalidatesTags: ["Notification"],
    }),
    updateNotification: build.mutation({
      query: ({ id, formData }) => ({
        url: `api/notifications/${id}`,
        method: "PUT",
        body: formData,
        formData: true,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: 'Notification', id },
        { type: 'Notification', id: 'LIST' }
      ],
    }),
    deleteNotification: build.mutation({
      query: (id) => ({
        url: `api/notifications/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Notification"],
    }),
    getLevelUsersCount: build.query({
      query: (categorySlug) => ({
        url: `api/levelUsersCount/${categorySlug}`,
        method: "GET",
      }),
      providesTags: ["Users"],
    }),
    getLevelUsers: build.query({
      query: ({ categorySlug, level, page = 0, pageSize = 10 }) => ({
        url: `api/levelUsers/${categorySlug}/${level}/${page + 1}`,
        method: "GET",
        params: { limit: pageSize }
      }),
      providesTags: ["Users"],
    }),
    getReferralUsers: build.query({
      query: ({ userId, page = 1, limit = 10 }) => ({
        url: `api/refUsers`,
        method: "GET",
        params: { userId, pageNo: page, limit }
      })
    }),
    updateRefUser: build.mutation({
      query: (data) => ({
        url: 'api/updateRefUser',
        method: 'PUT',
        body: data
      }),
      invalidatesTags: ['Customers']
    }),
    updateCustomer: build.mutation({
      query: ({ id, formData }) => ({
        url: `api/update/${id}`,
        method: "PUT",
        body: formData,
      }),
      invalidatesTags: ["Customers"],
    }),
    updateUserLevels: build.mutation({
      query: ({ userId, levels, reason }) => ({
        url: `/api/users/${userId}/levels`,
        method: 'PATCH',
        body: { levels, reason }
      }),
      invalidatesTags: ['Customers', 'LevelUsers']
    }),
    getLevelHistory: build.query({
      query: ({ page = 1, limit = 25, userId }) => ({
        url: 'api/level-history',
        params: {
          page,
          limit,
          ...(userId && { userId })
        }
      }),
      providesTags: ["LevelHistory"],
    }),
    getCoinTransactions: build.query({
      query: ({ page = 1, limit = 25, userId }) => ({
        url: 'api/coin-transactions',
        params: {
          page,
          limit,
          ...(userId && { userId })
        }
      }),
      providesTags: ["CoinTransactions"],
    }),
    getCustomerById: build.query({
      query: (id) => ({
        url: `client/customers/${id}`,
        method: 'GET',
      }),
      providesTags: (result, error, id) => [{ type: 'Customer', id }],
    }),
    getAds: build.query({
      query: () => ({
        url: "api/ads",
        method: "GET"
      }),
      providesTags: ["Ads"],
    }),
    createAd: build.mutation({
      query: (formData) => ({
        url: "api/ads",
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["Ads"],
    }),
    updateAd: build.mutation({
      query: ({ id, formData }) => ({
        url: `api/ads/${id}`,
        method: "PUT",
        body: formData,
      }),
      invalidatesTags: ["Ads"],
    }),
    deleteAd: build.mutation({
      query: (id) => ({
        url: `api/ads/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Ads"],
    }),
    getShopCategories: build.query({
      query: () => "api/shop-categories",
      providesTags: ["ShopCategories"],
    }),
    getShopCategory: build.query({
      query: (id) => `api/shop-categories/${id}`,
      providesTags: ["ShopCategories"],
    }),
    createShopCategory: build.mutation({
      query: (formData) => ({
        url: "api/shop-categories",
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["ShopCategories"],
    }),
    updateShopCategory: build.mutation({
      query: ({ id, formData }) => ({
        url: `api/shop-categories/${id}`,
        method: "PATCH",
        body: formData,
      }),
      invalidatesTags: ["ShopCategories"],
    }),
    deleteShopCategory: build.mutation({
      query: (id) => ({
        url: `api/shop-categories/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["ShopCategories"],
    }),
    updatePassword: build.mutation({
      query: (data) => ({
        url: "api/users/change-password",
        method: "POST",
        body: data,
      }),
    }),
    createAdmin: build.mutation({
      query: (data) => ({
        url: "api/users",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Admins"],
    }),
    updateAdmin: build.mutation({
      query: ({ id, ...data }) => ({
        url: `api/users/${id}`,
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: ["Admins"],
    }),
    getUserPermissions: build.query({
      query: (userId) => `api/permissions/${userId}`,
      providesTags: (result, error, userId) => [{ type: 'Permissions', id: userId }],
    }),
    updateUserPermissions: build.mutation({
      query: ({ userId, permissions }) => ({
        url: `api/permissions/${userId}`,
        method: 'POST',
        body: { permissions },
      }),
      invalidatesTags: (result, error, { userId }) => [
        { type: 'Permissions', id: userId },
        'Admins'
      ],
    }),
    getAllPermissions: build.query({
      query: () => 'api/permissions',
      providesTags: ['Permissions'],
    }),
    getPermissionItems: build.query({
      query: () => 'api/permissions/items',
      providesTags: ['PermissionItems'],
    }),
    createPermissionItem: build.mutation({
      query: (data) => ({
        url: 'api/permissions/items',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['PermissionItems'],
    }),
    updatePermissionItem: build.mutation({
      query: ({ id, ...data }) => ({
        url: `api/permissions/items/${id}`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['PermissionItems'],
    }),
    deletePermissionItem: build.mutation({
      query: (id) => ({
        url: `api/permissions/items/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['PermissionItems'],
    }),
    initializePermissionItems: build.mutation({
      query: () => ({
        url: 'api/permissions/items/initialize',
        method: 'POST',
      }),
      invalidatesTags: ['PermissionItems'],
    }),
    getActivities: build.query({
      query: (params) => ({
        url: 'api/activities',
        method: 'GET',
        params
      }),
      providesTags: ['Activities']
    }),
    getActivityModules: build.query({
      query: () => 'api/activities/modules',
      providesTags: ['Activities']
    }),
  }),
});

export const {
  useGetUserQuery,
  useGetProductsQuery,
  useGetCustomersQuery,
  useGetTransactionsQuery,
  useGetGeographyQuery,
  useGetSalesQuery,
  useGetAdminsQuery,
  useGetUserPerformanceQuery,
  useGetDashboardQuery,
  useCreatePostMutation,
  useUpdateUserMutation, 
  useDeleteUserMutation,
  useLoginMutation,
  useGetTeacherQuery,
  useCreateTeacherMutation,
  useUpdateTeacherMutation,
  useDeleteTeacherMutation,
  useGetCategoryQuery,
  useGetHistoriesQuery,
  useGetMcqCompetitionsQuery,
  useGetHistoryByUserAndSlugQuery,
  useGetNotificationsQuery,
  useCreateNotificationMutation,
  useUpdateNotificationMutation,
  useDeleteNotificationMutation,
  useGetLevelUsersCountQuery,
  useGetLevelUsersQuery,
  useGetReferralUsersQuery,
  useUpdateRefUserMutation,
  useUpdateCustomerMutation,
  useUpdateUserLevelsMutation,
  useGetLevelHistoryQuery,
  useGetCoinTransactionsQuery,
  useGetCustomerByIdQuery,
  useGetAdsQuery,
  useCreateAdMutation,
  useUpdateAdMutation,
  useDeleteAdMutation,
  useGetShopCategoriesQuery,
  useGetShopCategoryQuery,
  useCreateShopCategoryMutation,
  useUpdateShopCategoryMutation,
  useDeleteShopCategoryMutation,
  useUpdatePasswordMutation,
  useCreateAdminMutation,
  useUpdateAdminMutation,
  useGetUserPermissionsQuery,
  useUpdateUserPermissionsMutation,
  useGetAllPermissionsQuery,
  useGetPermissionItemsQuery,
  useCreatePermissionItemMutation,
  useUpdatePermissionItemMutation,
  useDeletePermissionItemMutation,
  useInitializePermissionItemsMutation,
  useGetActivitiesQuery,
  useGetActivityModulesQuery,
} = api;
