import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  Box,
  Typography,
  IconButton,
  CircularProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { Close as CloseIcon, Check as CheckIcon, Clear as ClearIcon } from "@mui/icons-material";
import { useUpdatePasswordMutation } from "state/api";
import { toast } from "react-toastify";
import { useTheme } from "@mui/material/styles";

const PasswordChangeModal = ({ open, onClose }) => {
  const theme = useTheme();
  const [passwordData, setPasswordData] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [updatePassword, { isLoading }] = useUpdatePasswordMutation();

  const handlePasswordChange = (e) => {
    setPasswordData({
      ...passwordData,
      [e.target.name]: e.target.value
    });
  };

  const handleCloseModal = () => {
    setPasswordData({
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
    });
    onClose();
  };

  const isLengthValid = passwordData.newPassword.length >= 6;
  const hasMatch = passwordData.newPassword === passwordData.confirmPassword;
  const hasCurrentPassword = passwordData.currentPassword.length > 0;

  const handleSubmitPasswordChange = async () => {
    // Validation
    if (!hasCurrentPassword) {
      toast.error("Current password is required");
      return;
    }
    if (!isLengthValid) {
      toast.error("Password must be at least 6 characters");
      return;
    }
    if (!hasMatch) {
      toast.error("Passwords do not match");
      return;
    }

    try {
      await updatePassword({
        currentPassword: passwordData.currentPassword,
        newPassword: passwordData.newPassword
      }).unwrap();
      
      toast.success("Password updated successfully");
      handleCloseModal();
    } catch (error) {
      toast.error(error?.data?.message || "Failed to update password");
    }
  };

  return (
    <Dialog 
      open={open} 
      onClose={handleCloseModal}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>
        Change Password
        <IconButton
          aria-label="close"
          onClick={handleCloseModal}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          name="currentPassword"
          label="Current Password"
          type="password"
          fullWidth
          variant="outlined"
          value={passwordData.currentPassword}
          onChange={handlePasswordChange}
        />
        <TextField
          margin="dense"
          name="newPassword"
          label="New Password"
          type="password"
          fullWidth
          variant="outlined"
          value={passwordData.newPassword}
          onChange={handlePasswordChange}
        />
        <TextField
          margin="dense"
          name="confirmPassword"
          label="Confirm New Password"
          type="password"
          fullWidth
          variant="outlined"
          value={passwordData.confirmPassword}
          onChange={handlePasswordChange}
        />
        
        <Box mt={2}>
          <Typography variant="subtitle2" color="textSecondary">
            Password Requirements:
          </Typography>
          <List dense>
            <ListItem>
              <ListItemIcon>
                {hasCurrentPassword ? (
                  <CheckIcon color="success" fontSize="small" />
                ) : (
                  <ClearIcon color="error" fontSize="small" />
                )}
              </ListItemIcon>
              <ListItemText primary="Current password is required" />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                {isLengthValid ? (
                  <CheckIcon color="success" fontSize="small" />
                ) : (
                  <ClearIcon color="error" fontSize="small" />
                )}
              </ListItemIcon>
              <ListItemText primary="At least 6 characters long" />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                {hasMatch && passwordData.confirmPassword ? (
                  <CheckIcon color="success" fontSize="small" />
                ) : (
                  <ClearIcon color="error" fontSize="small" />
                )}
              </ListItemIcon>
              <ListItemText primary="Passwords match" />
            </ListItem>
          </List>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseModal}>Cancel</Button>
        <Button 
          onClick={handleSubmitPasswordChange} 
          variant="contained" 
          disabled={isLoading || !hasCurrentPassword || !isLengthValid || !hasMatch}
          startIcon={isLoading ? <CircularProgress size={20} /> : null}
        >
          {isLoading ? "Updating..." : "Update Password"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PasswordChangeModal; 