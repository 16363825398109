import React from "react";
import {
  Box,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { useGetLevelUsersCountQuery } from "state/api";
import Header from "components/Header";
import { useNavigate, useLocation } from 'react-router-dom';

const CategoryLevels = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const isNonMobile = useMediaQuery("(min-width: 1000px)");
  const { name, id, slug } = location.state;

  // Use the new query hook
  const { data: levelsData, isLoading } = useGetLevelUsersCountQuery(slug);

  const goLevelUsers = (e) => {
    navigate('/category/level/users', { state: e });
  };

  return (
    <Box m="1.5rem 2.5rem">
      <Header title={`Levels of ${name}`} subtitle="Level's report based on selected categories" />
      <Box
        mt="40px"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.primary.light,
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderTop: "none",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },
        }}
      >
        <div className="container-fluid mt-5">
          <div className="row mt-3">
            {isLoading ? (
              <div className="col-md-12 mb-3">
                <div className="card shadow-sm" style={{backgroundColor: theme.palette.background.alt, color: theme.palette.text.primary}}>
                  <div className="card-body">
                    <h5 className="card-title">Loading...</h5>
                  </div>
                </div>
              </div>
            ) : levelsData?.data?.length > 0 ? (
              levelsData.data.map((item) => (
                <div 
                  className="col-md-4 mb-3" 
                  key={`${item.level}_${id}_${name}`} 
                  onClick={() => goLevelUsers({
                    com_type_name: name, 
                    slug: slug, 
                    com_type_id: id, 
                    level: item.level
                  })}
                >
                  <div className="card shadow-sm" style={{backgroundColor: theme.palette.background.alt, color: theme.palette.text.primary}}>
                    <div className="card-body">
                      <h5 className="card-title">Level {item.level}</h5>
                      <div className="card-subtitle">{item.users} user(s)</div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <Typography variant="h6" color="textSecondary">No levels found.</Typography>
            )}
          </div>
        </div>
      </Box>
    </Box>
  );
};

export default CategoryLevels;

