import React, { useState } from "react";
import {
  Box,
  Button,
  useTheme,
  Typography,
  IconButton,
  Chip,
  Grid,
  Card,
  CardContent,
  CardMedia,
  Stack,
  Dialog,
  DialogTitle,
  DialogContent
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useGetShopCategoriesQuery, useDeleteShopCategoryMutation } from "state/api";
import Header from "components/Header";
import { toast } from "react-toastify";
import ShopCategoryForm from "./ShopCategoryForm";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CloseIcon from "@mui/icons-material/Close";

const ShopCategories = () => {
  const theme = useTheme();
  const [openForm, setOpenForm] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [openPreview, setOpenPreview] = useState(false);
  const [previewCategory, setPreviewCategory] = useState(null);

  const { data, isLoading } = useGetShopCategoriesQuery();
  const [deleteShopCategory] = useDeleteShopCategoryMutation();

  const handleOpenForm = (category = null) => {
    setSelectedCategory(category);
    setOpenForm(true);
  };

  const handleCloseForm = () => {
    setOpenForm(false);
    setSelectedCategory(null);
  };

  const handleOpenPreview = (category) => {
    setPreviewCategory(category);
    setOpenPreview(true);
  };

  const handleClosePreview = () => {
    setOpenPreview(false);
    setPreviewCategory(null);
  };

  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this category?")) {
      try {
        await deleteShopCategory(id).unwrap();
        toast.success("Category deleted successfully");
      } catch (error) {
        toast.error(error?.data?.message || "An error occurred");
      }
    }
  };

  const columns = [
    {
      field: "thumb",
      headerName: "Thumbnail",
      flex: 0.5,
      renderCell: (params) => (
        <Box
          component="img"
          sx={{
            height: 40,
            width: 40,
            borderRadius: "4px",
            objectFit: "cover",
          }}
          src={
            params.row.thumb
              ? `${process.env.REACT_APP_API_URL}/img/${params.row.thumb}`
              : "/default-thumb.png"
          }
          alt={params.row.name}
        />
      ),
    },
    { field: "name", headerName: "Name", flex: 1 },
    { field: "slug", headerName: "Slug", flex: 1 },
    {
      field: "description",
      headerName: "Description",
      flex: 1,
      renderCell: (params) => (
        <Typography
          variant="body2"
          sx={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            WebkitLineClamp: 2,
            WebkitBoxOrient: "vertical",
          }}
        >
          {params.row.description}
        </Typography>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      flex: 0.5,
      renderCell: (params) => (
        <Chip
          label={params.row.status}
          color={params.row.status === "Active" ? "success" : "warning"}
          size="small"
        />
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      renderCell: (params) => (
        <Stack direction="row" spacing={1}>
          <IconButton
            size="small"
            onClick={() => handleOpenPreview(params.row)}
            sx={{ 
              color: 'white',
              backgroundColor: 'info.main',
              '&:hover': { backgroundColor: 'info.dark' }
            }}
          >
            <VisibilityIcon />
          </IconButton>
          <IconButton
            size="small"
            onClick={() => handleOpenForm(params.row)}
            sx={{ 
              color: 'white',
              backgroundColor: 'primary.main',
              '&:hover': { backgroundColor: 'primary.dark' }
            }}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            size="small"
            onClick={() => handleDelete(params.row._id)}
            sx={{ 
              color: 'white',
              backgroundColor: 'error.main',
              '&:hover': { backgroundColor: 'error.dark' }
            }}
          >
            <DeleteIcon />
          </IconButton>
        </Stack>
      ),
    },
  ];

  return (
    <Box m="1.5rem 2.5rem">
      <Header title="SHOP CATEGORIES" subtitle="Manage your shop categories" />
      <Box display="flex" justifyContent="flex-end" mb={2}>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => handleOpenForm()}
        >
          Add New Category
        </Button>
      </Box>

      <Box
        height="80vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.primary.light,
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderTop: "none",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },
        }}
      >
        <DataGrid
          loading={isLoading}
          getRowId={(row) => row._id}
          rows={data?.data || []}
          columns={columns}
          pageSize={10}
        />
      </Box>

      <ShopCategoryForm
        open={openForm}
        onClose={handleCloseForm}
        category={selectedCategory}
      />

      <Dialog open={openPreview} onClose={handleClosePreview} maxWidth="md" fullWidth>
        <DialogTitle>
          Category Preview
          <IconButton
            aria-label="close"
            onClick={handleClosePreview}
            sx={{ position: "absolute", right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {previewCategory && (
            <Card>
              {previewCategory.banner && (
                <CardMedia
                  component="img"
                  height="200"
                  image={`${process.env.REACT_APP_API_URL}/img/${previewCategory.banner}`}
                  alt={previewCategory.name}
                />
              )}
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={4}>
                    {previewCategory.thumb && (
                      <Box
                        component="img"
                        sx={{
                          height: 150,
                          width: "100%",
                          borderRadius: "4px",
                          objectFit: "cover",
                        }}
                        src={`${process.env.REACT_APP_API_URL}/img/${previewCategory.thumb}`}
                        alt={previewCategory.name}
                      />
                    )}
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <Typography variant="h5" gutterBottom>
                      {previewCategory.name}
                    </Typography>
                    <Typography variant="body2" color="text.secondary" gutterBottom>
                      Slug: {previewCategory.slug}
                    </Typography>
                    <Chip
                      label={previewCategory.status}
                      color={previewCategory.status === "Active" ? "success" : "warning"}
                      size="small"
                      sx={{ mb: 2 }}
                    />
                    <Typography variant="body1">
                      {previewCategory.description}
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          )}
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default ShopCategories; 