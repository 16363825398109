import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Typography,
  IconButton,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import { useCreateShopCategoryMutation, useUpdateShopCategoryMutation } from "state/api";
import { toast } from "react-toastify";

const ShopCategoryForm = ({ open, onClose, category }) => {
  const theme = useTheme();
  const [formData, setFormData] = useState({
    name: "",
    slug: "",
    description: "",
    status: "Active",
  });

  const [thumbPreview, setThumbPreview] = useState(null);
  const [bannerPreview, setBannerPreview] = useState(null);
  const [thumbFile, setThumbFile] = useState(null);
  const [bannerFile, setBannerFile] = useState(null);

  const [createShopCategory, { isLoading: isCreating }] = useCreateShopCategoryMutation();
  const [updateShopCategory, { isLoading: isUpdating }] = useUpdateShopCategoryMutation();

  useEffect(() => {
    if (category) {
      setFormData({
        name: category.name || "",
        slug: category.slug || "",
        description: category.description || "",
        status: category.status || "Active",
      });

      if (category.thumb) {
        setThumbPreview(`${process.env.REACT_APP_API_URL}/img/${category.thumb}`);
      }

      if (category.banner) {
        setBannerPreview(`${process.env.REACT_APP_API_URL}/img/${category.banner}`);
      }
    } else {
      setFormData({
        name: "",
        slug: "",
        description: "",
        status: "Active",
      });
      setThumbPreview(null);
      setBannerPreview(null);
      setThumbFile(null);
      setBannerFile(null);
    }
  }, [category, open]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));

    // Auto-generate slug from name
    if (name === "name" && (!formData.slug || formData.slug === "")) {
      const slug = value
        .toLowerCase()
        .replace(/[^a-z0-9]+/g, "-")
        .replace(/(^-|-$)/g, "");
      setFormData((prev) => ({ ...prev, slug }));
    }
  };

  const handleThumbChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setThumbFile(file);
      setThumbPreview(URL.createObjectURL(file));
    }
  };

  const handleBannerChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setBannerFile(file);
      setBannerPreview(URL.createObjectURL(file));
    }
  };

  const handleSubmit = async () => {
    try {
      const form = new FormData();
      form.append("name", formData.name);
      form.append("slug", formData.slug);
      form.append("description", formData.description);
      form.append("status", formData.status);

      if (thumbFile) {
        form.append("thumb", thumbFile);
      }

      if (bannerFile) {
        form.append("banner", bannerFile);
      }

      if (category) {
        await updateShopCategory({ id: category._id, formData: form }).unwrap();
        toast.success("Category updated successfully");
      } else {
        await createShopCategory(form).unwrap();
        toast.success("Category created successfully");
      }

      onClose();
    } catch (error) {
      toast.error(error?.data?.message || "An error occurred");
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        {category ? "Edit Shop Category" : "Add Shop Category"}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{ position: "absolute", right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12} md={6}>
            <TextField
              name="name"
              label="Category Name"
              fullWidth
              value={formData.name}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              name="slug"
              label="Slug"
              fullWidth
              value={formData.slug}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="description"
              label="Description"
              fullWidth
              multiline
              rows={4}
              value={formData.description}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <InputLabel>Status</InputLabel>
              <Select
                name="status"
                value={formData.status}
                label="Status"
                onChange={handleChange}
              >
                <MenuItem value="Active">Active</MenuItem>
                <MenuItem value="Inactive">Inactive</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box>
              <Typography variant="subtitle1" gutterBottom>
                Thumbnail Image
              </Typography>
              <input
                accept="image/*"
                id="thumb-upload"
                type="file"
                style={{ display: "none" }}
                onChange={handleThumbChange}
              />
              <label htmlFor="thumb-upload">
                <Button variant="contained" component="span">
                  Upload Thumbnail
                </Button>
              </label>
              {thumbPreview && (
                <Box mt={2}>
                  <img
                    src={thumbPreview}
                    alt="Thumbnail Preview"
                    style={{
                      maxWidth: "100%",
                      maxHeight: "150px",
                      borderRadius: "4px",
                    }}
                  />
                </Box>
              )}
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box>
              <Typography variant="subtitle1" gutterBottom>
                Banner Image
              </Typography>
              <input
                accept="image/*"
                id="banner-upload"
                type="file"
                style={{ display: "none" }}
                onChange={handleBannerChange}
              />
              <label htmlFor="banner-upload">
                <Button variant="contained" component="span">
                  Upload Banner
                </Button>
              </label>
              {bannerPreview && (
                <Box mt={2}>
                  <img
                    src={bannerPreview}
                    alt="Banner Preview"
                    style={{
                      maxWidth: "100%",
                      maxHeight: "200px",
                      borderRadius: "4px",
                    }}
                  />
                </Box>
              )}
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          disabled={isCreating || isUpdating || !formData.name || !formData.slug}
        >
          {category ? "Update" : "Create"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ShopCategoryForm; 