import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  useTheme,
  TextField,
  Stack,
  Menu,
  MenuItem,
} from "@mui/material";
import { useGetCustomersQuery } from "state/api";
import Header from "components/Header";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import SearchIcon from '@mui/icons-material/Search';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import StarIcon from '@mui/icons-material/Star';
import EditLevelsModal from './EditLevelsModal';
import { useUpdateUserLevelsMutation } from 'state/api';
import DataGridCustomToolbar from "components/DataGridCustomToolbar";
import CustomColumnMenu from "components/DataGridCustomColumnMenu";

const Customers = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [searchInput, setSearchInput] = useState("");
  const [search, setSearch] = useState("");
  const [filteredRowCount, setFilteredRowCount] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [isEditLevelsOpen, setIsEditLevelsOpen] = useState(false);
  const [selectedUserForLevels, setSelectedUserForLevels] = useState(null);
  const [sortModel, setSortModel] = useState([
    {
      field: 'createdAt',
      sort: 'desc',
    },
  ]);
  const [filterModel, setFilterModel] = useState({
    items: []
  });

  const getSortObject = () => {
    if (!sortModel.length) return { createdAt: -1 };
    
    const { field, sort } = sortModel[0];
    return { [field]: sort === 'asc' ? 1 : -1 };
  };

  const { data, isLoading, isFetching } = useGetCustomersQuery({
    page: page + 1,
    limit: pageSize,
    search: searchInput,
    sortField: sortModel[0]?.field || 'createdAt',
    sortOrder: sortModel[0]?.sort || 'desc'
  });

  useEffect(() => {
    setPage(0);
  }, [search, JSON.stringify(sortModel)]);

  const handlePaginationChange = (model) => {
    setPage(model.page);
    setPageSize(model.pageSize);
  };

  function handleViewClick(params) {
    navigate("/user", {
      state: params,
    });
    handleClose();
  }
  function handleEditClick(params) {
    navigate("/edit-customer", {
      state: params,
    });
    handleClose();
  }
  function handleEditReferrerClick(params) {
    // console.log(params);
    navigate(`/customers/${params?._id}/edit-referrer`);
    handleClose();
  }

  const handleEditLevelsClick = (params) => {
    setSelectedUserForLevels(params);
    setIsEditLevelsOpen(true);
    handleClose();
  };

  const handleMenuClick = (event, params) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(params);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedRow(null);
  };

  const columns = [
    {
      field: "serialNo",
      headerName: "S.No",
      flex: 0.3,
    },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      filterable: true,
      sortable: true,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      filterable: true,
      sortable: true,
    },
    {
      field: "phone",
      headerName: "Phone",
      flex: 0.5,
      filterable: true,
      sortable: true,
      renderCell: (params) => {
        return params.value || 'N/A';
      },
    },
    {
      field: "country",
      headerName: "Country",
      flex: 0.4,
      filterable: true,
      sortable: true,
      renderCell: (params) => {
        return params.value?.name || 'N/A';
      },
    },
    {
      field: "rank",
      headerName: "Rank",
      flex: 0.4,
      filterable: true,
      sortable: true,
      hide: true,
    },
    {
      field: "refUserId",
      headerName: "Referrer",
      flex: 1,
      filterable: true,
      sortable: true,
      // renderCell: (params) => {
      //   return params.value?.name || 'N/A';
      // },
      valueGetter: (params) =>
        params.row.refUserId
          ? `${params.row.refUserId?.email}\n${params.row.refUserId?.name}`
          : "",
      renderCell: (params) => (
        <div style={{ whiteSpace: "pre-line" }}>{params.value}</div>
      ),
    },
    {
      field: "myRefTotal",
      headerName: "MyRefTotal",
      flex: 0.5,
      type: 'number',
      filterable: true,
      sortable: true,
    },
    {
      field: "createdAtFormat",
      headerName: "Created At",
      flex: 1,
      filterable: true,
      sortable: true,
      hide: true,
    },
    {
      field: "action",
      headerName: "Actions",
      flex: 0.5,
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return (
          <>
            <Button
              onClick={(e) => handleMenuClick(e, params.row)}
              sx={{ color: theme.palette.secondary[100] }}
            >
              <MoreVertIcon />
            </Button>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl) && selectedRow?._id === params.row._id}
              onClose={handleClose}
            >
              <MenuItem onClick={() => handleViewClick(selectedRow)}>
                <VisibilityIcon sx={{ mr: 1 }} /> View
              </MenuItem>
              <MenuItem onClick={() => handleEditClick(selectedRow)}>
                <EditIcon sx={{ mr: 1 }} /> Edit
              </MenuItem>
              <MenuItem onClick={() => handleEditReferrerClick(selectedRow)}>
                <PersonAddIcon sx={{ mr: 1 }} /> Referrer
              </MenuItem>
              <MenuItem onClick={() => handleEditLevelsClick(selectedRow)}>
                <StarIcon sx={{ mr: 1 }} /> Edit Levels
              </MenuItem>
            </Menu>
          </>
        );
      },
    },
  ];

  return (
    <Box m="1.5rem 2.5rem">
      <Header title="CUSTOMERS" subtitle="List of Customers" />
      <Box
        mt="40px"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.primary.light,
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderTop: "none",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer": {
            backgroundColor: theme.palette.background.alt,
            borderRadius: "0.55rem 0.55rem 0 0",
            paddingX: "1rem",
          },
        }}
      >
        <DataGrid
          loading={isLoading || isFetching}
          getRowId={(row) => row._id}
          rows={data?.data || []}
          columns={columns}
          rowCount={data?.pagination?.total || 0}
          pagination
          page={page}
          pageSize={pageSize}
          paginationMode="server"
          sortingMode="server"
          onPageChange={(newPage) => setPage(newPage)}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          onSortModelChange={(newSortModel) => {
            setSortModel(newSortModel);
          }}
          components={{
            Toolbar: GridToolbar,
            ColumnMenu: CustomColumnMenu,
          }}
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
          filterMode="server"
          filterModel={filterModel}
          onFilterModelChange={(newFilterModel) => {
            setFilterModel(newFilterModel);
            setSearchInput(newFilterModel.quickFilterValues?.[0] || "");
          }}
          sortModel={sortModel}
          disableSelectionOnClick
          disableDensitySelector={false}
          initialState={{
            sorting: {
              sortModel: [{ field: 'createdAt', sort: 'desc' }],
            },
          }}
        />
      </Box>

      <EditLevelsModal
        open={isEditLevelsOpen}
        onClose={() => setIsEditLevelsOpen(false)}
        user={selectedUserForLevels}
      />
    </Box>
  );
};

export default Customers;
