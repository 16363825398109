import { CssBaseline, ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { themeSettings } from "theme";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Layout from "scenes/layout";
import Dashboard from "scenes/dashboard";
import Categories from "scenes/categories";
import Customers from "scenes/customers";
import User from "scenes/user";
import Transactions from "scenes/transactions";
import Geography from "scenes/geography";
import Overview from "scenes/overview";
import Daily from "scenes/daily";
import Monthly from "scenes/monthly";
import Breakdown from "scenes/breakdown";
import Admin from "scenes/admin";
import Ads from "scenes/ads";
import Performance from "scenes/performance";
import Login from "scenes/login";
import EditCustomer from "scenes/customers/EditCustomer";
import ReleaseMessage from "scenes/ReleaseMessage";
import NotFound from "scenes/NotFound";
import Teachers from "scenes/teachers";
import CategoryLevels from "scenes/categories/CategoryLevels";
import CategoryLevelUsers from "scenes/categories/CategoryLevelUsers";
import MCQManagement from "scenes/questions";
import McqCompetition from "scenes/mcqCompetition";
import Profile from "scenes/profile";
import History from "scenes/history";
import PrivateRoute from "scenes/login/privateRoute";
import PrivacyPolicyPage from "scenes/FrontPages/PrivacyPolicyPage";
import InvitePage from "scenes/FrontPages/InvitePage";
import { Helmet } from "react-helmet";
import DeletePage from "scenes/FrontPages/DeletePage";
import CategoryLevelUserHistory from "scenes/categories/CategoryLevelUserHistory";
import Notification from "scenes/notification";
import EditReferrer from "scenes/customers/EditReferrer";
import LevelHistory from "scenes/levelHistory";
import CoinTransactions from "scenes/transactions";
import ShopCategories from "scenes/shop-categories";
import AccessControl from "components/AccessControl";
import Questions from "scenes/questions";
import ActivityLogs from "scenes/activity-logs";


function App() {
  const mode = useSelector((state) => state.global.mode);
  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);
  const user = useSelector((state) => state.auth.user);
  // console.log(user);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  return (
    <div className="app">
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <CssBaseline />
            <Routes>

              { isAuthenticated ? (
                <Route element={<Layout />}>
                  
                  <Route path="/securelogin" element={<Dashboard/>} />
                  { 
                  user?.role === "superadmin" || user?.role === "admin" ? (
                    <>
                     <Route path="/dashboard" element={<Dashboard />} />
                     <Route path="/categories" element={<AccessControl><Categories /></AccessControl>} />
                     <Route path="/category/levels" element={<AccessControl><CategoryLevels /></AccessControl>} />
                     <Route path="/category/level/users/" element={<AccessControl><CategoryLevelUsers /></AccessControl>} />
                     <Route path="/category/level/user/history" element={<AccessControl><CategoryLevelUserHistory /></AccessControl>} />
                     <Route path="/customers" element={<AccessControl requiredPermission="manageUsers"><Customers /></AccessControl>} />
                     <Route path="/user" element={<AccessControl><User /></AccessControl>} />
                     <Route path="/edit-customer" element={<AccessControl><EditCustomer /></AccessControl>} />
                     <Route path="/customers/:userId/edit-referrer" element={<AccessControl><EditReferrer /></AccessControl>} />
                     <Route path="/teachers" element={<AccessControl><Teachers /></AccessControl>} />
                     <Route path="/admin" element={<AccessControl requiredPermission="manageUsers"><Admin /></AccessControl>} />
                     <Route path="/profile" element={<AccessControl><Profile /></AccessControl>} />
                     <Route path="/mcq-management" element={<AccessControl requiredPermission="manageQuestions"><MCQManagement /></AccessControl>} />
                     <Route path="/ads" element={<AccessControl requiredPermission="manageAds"><Ads /></AccessControl>} />
                     <Route path="/history" element={<AccessControl requiredPermission="viewGameHistory"><History/></AccessControl>} />
                     <Route path="/mcqCompetition" element={<AccessControl requiredPermission="viewMcqHistory"><McqCompetition/></AccessControl>} />
                     <Route path="/notifications" element={<AccessControl requiredPermission="manageNotifications"><Notification/></AccessControl>} />
                     <Route path="/level-change-history" element={<AccessControl requiredPermission="viewLevelChangeHistory"><LevelHistory /></AccessControl>} />
                     <Route path="/coin-transactions" element={<AccessControl requiredPermission="viewCoinHistory"><CoinTransactions /></AccessControl>} />
                     <Route path="/shop-categories" element={<AccessControl requiredPermission="manageShopCategories"><ShopCategories /></AccessControl>} />
                     <Route path="/activity-logs" element={<AccessControl requiredPermission="viewActivityLogs"><ActivityLogs /></AccessControl>} />
                    </>
                 ): user?.role === "teacher" ? (
                     <>
                      <Route path="/dashboard" element={<AccessControl><Dashboard /></AccessControl>} />
                      <Route path="/profile" element={<AccessControl><Profile /></AccessControl>} />
                      <Route path="/mcq-management" element={<AccessControl requiredPermission="manageQuestions"><MCQManagement /></AccessControl>} />
                     </>
                  ):(
                    <>
                     <Route path="/dashboard" element={<AccessControl><Dashboard /></AccessControl>} />
                     <Route path="/profile" element={<AccessControl><Profile /></AccessControl>} />
                    </>
                  )}
                </Route>
              ):(
                <></>
              )}
              <Route path="/" element={<ReleaseMessage/>} />
              <Route path="/securelogin" element={<Login/>} />
              <Route path="/privacy-policy" element={<PrivacyPolicyPage/>} />
              <Route path="/invite/:name/:userId" element={<InvitePage/>} />
              <Route path="/delete-account" element={<DeletePage/>} />
              <Route path="/questions" element={<AccessControl requiredPermission="manageQuestions"><Questions /></AccessControl>} />
              <Route path="*" element={<NotFound />} />
              
          </Routes>   
        
        </ThemeProvider>
      </BrowserRouter>

    </div>
  );
}

export default App;