import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Button,
  TextField,
  useTheme,
  FormControl,
  Autocomplete,
  Stack,
  Grid,
  Paper,
  CircularProgress,
  Alert,
} from "@mui/material";
import Header from "components/Header";
import { useGetCustomerByIdQuery, useGetCustomersQuery, useUpdateRefUserMutation } from "state/api";

const EditReferrer = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { userId } = useParams();
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedReferrer, setSelectedReferrer] = useState(null);

  // Get current user data
  const { 
    data: currentUser, 
    isLoading: isLoadingUser,
    error: userError
  } = useGetCustomerByIdQuery(userId);

  // Get potential referrers with search
  const { 
    data: customersData, 
    isLoading: isLoadingCustomers 
  } = useGetCustomersQuery({
    page: 1,
    limit: 100,
    search: searchTerm
  });

  const [updateRefUser] = useUpdateRefUserMutation();

  // Fix: Changed useState to useEffect for initial referrer selection
  useEffect(() => {
    if (currentUser?.refUserId) {
      setSelectedReferrer(currentUser.refUserId);
    } else {
      setSelectedReferrer(null);
    }
  }, [currentUser]);

  const handleSubmit = async () => {
    try {
      await updateRefUser({
        userId: currentUser._id,
        refUserId: selectedReferrer?._id || ''
      }).unwrap();
      
      navigate('/customers');
    } catch (error) {
      console.error('Failed to update referrer:', error);
    }
  };

  // Filter out the current user from the referrer options
  const referrerOptions = customersData?.data?.filter(customer => 
    customer._id !== userId
  ) || [];

  if (isLoadingUser || isLoadingCustomers) {
    return (
      <Box m="1.5rem 2.5rem">
        <Header title="EDIT REFERRER" subtitle="Loading..." />
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="400px">
          <CircularProgress />
        </Box>
      </Box>
    );
  }

  if (userError) {
    return (
      <Box m="1.5rem 2.5rem">
        <Header title="Error" subtitle="Failed to load user data" />
        <Alert severity="error" sx={{ mt: 2 }}>
          {userError.data?.message || 'An error occurred while loading the user'}
        </Alert>
      </Box>
    );
  }

  if (!currentUser) {
    return (
      <Box m="1.5rem 2.5rem">
        <Header title="User Not Found" subtitle="Please check the user ID and try again" />
      </Box>
    );
  }

  return (
    <Box m="1.5rem 2.5rem">
      <Header 
        title="EDIT REFERRER" 
        subtitle={`Update referrer for ${currentUser.name}`} 
      />
      <Box mt="40px">
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12} md={6}>
            <Paper
              elevation={3}
              sx={{
                p: "1.5rem",
                backgroundColor: theme.palette.background.alt,
              }}
            >
              <Stack spacing={3}>
                <Box>
                  <TextField
                    fullWidth
                    label="Name"
                    value={currentUser.name}
                    InputProps={{
                      readOnly: true,
                    }}
                    sx={{ mb: 2 }}
                  />
                  <TextField
                    fullWidth
                    label="Email"
                    value={currentUser.email}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Box>

                <Box>
                  <FormControl fullWidth>
                    <Autocomplete
                      value={selectedReferrer}
                      onChange={(event, newValue) => {
                        setSelectedReferrer(newValue);
                      }}
                      onInputChange={(event, newInputValue) => {
                        setSearchTerm(newInputValue);
                      }}
                      options={referrerOptions}
                      getOptionLabel={(option) => 
                        option ? `${option.name} (${option.email})` : ''
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select Referrer"
                          placeholder="Search by name or email..."
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <>
                                {isLoadingCustomers && 
                                  <CircularProgress color="inherit" size={20} />
                                }
                                {params.InputProps.endAdornment}
                              </>
                            ),
                          }}
                        />
                      )}
                      renderOption={(props, option) => (
                        <li {...props}>
                          <Box>
                            <Box sx={{ fontWeight: 'bold' }}>{option.name}</Box>
                            <Box sx={{ fontSize: '0.8rem', color: 'text.secondary' }}>
                              {option.email}
                            </Box>
                          </Box>
                        </li>
                      )}
                      isOptionEqualToValue={(option, value) => 
                        option?._id === value?._id
                      }
                      loading={isLoadingCustomers}
                      loadingText="Loading..."
                      noOptionsText="No users found"
                      filterOptions={(x) => x}
                    />
                  </FormControl>
                </Box>

                <Box display="flex" justifyContent="flex-end" gap={2}>
                  <Button
                    variant="outlined"
                    onClick={() => navigate('/customers')}
                    sx={{
                      color: theme.palette.secondary[300],
                      borderColor: theme.palette.secondary[300],
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    onClick={handleSubmit}
                    sx={{
                      backgroundColor: theme.palette.secondary[300],
                      color: theme.palette.background.alt,
                      "&:hover": {
                        backgroundColor: theme.palette.secondary[100],
                      },
                    }}
                  >
                    Save Changes
                  </Button>
                </Box>
              </Stack>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default EditReferrer;